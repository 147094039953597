<template>
  <div
    class="carousel-item"
    ref="carouselItem"
    :data-background="image"
    :class="{ active }"
  >
    <div class="carousel-caption">
      <div class="flex-grow">
        <span class="uppercase text-white font-medium text-sm">{{
          subtitle
        }}</span
        ><br />
        <span
          class="uppercase text-white font-bold text-2xl"
          v-html="title"
        ></span>
      </div>
      <img
        class="carousel-item-zoom"
        src="@/assets/img/zoom-in.svg"
        alt="Zoom"
        @click="$emit('image-selected', image)"
      />
    </div>
  </div>
</template>

<script>
import { lazyLoadImages } from '@/assets/js/lazy-load'
let lazyLoadDispose = null

export default {
  name: 'CarouselItem',
  props: ['image', 'title', 'subtitle', 'active'],
  emits: ['image-selected'],
  mounted() {
    lazyLoadDispose = lazyLoadImages([this.$refs.carouselItem])
  },
  unmounted() {
    lazyLoadDispose && lazyLoadDispose()
  },
}
</script>

<style scoped>
.carousel-item {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover !important;
  background-position: top center !important;
  filter: grayscale(1) brightness(50%);
  transition: filter 0.3s ease;
  will-change: filter;
}
@media (min-width: 1024px) {
  .carousel-item {
    width: 55vw;
    max-width: 760px;
  }
}
.carousel-item.active {
  filter: grayscale(1);
}
.carousel-item.active:hover {
  filter: none;
}
/*
.carousel-item::after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  transition: opacity 0.4s ease;
  will-change: opacity;
}*/
.carousel-item > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  filter: grayscale(1);
  transition: filter 0.4s ease;
}
/*.carousel-item.active::after {
  opacity: 0
}*/
.carousel-item.active:hover > img {
  filter: none;
}
.carousel-caption {
  position: absolute;
  bottom: 0;
  padding-left: 2.5rem;
  padding-bottom: 3rem;
  padding-right: 2.5rem;
  padding-top: 3rem;
  left: 0;
  right: 0;
  display: flex;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  justify-content: space-around;
  align-items: flex-end;
  opacity: 0;
  transition: opacity 0.5s ease;
  will-change: opacity;
}
.carousel-item.active > .carousel-caption {
  opacity: 1;
}
.carousel-item-zoom {
  cursor: pointer;
  fill: white;
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  z-index: 100;
}
</style>
