// Assets
import luxuryGaming from '@/assets/img/publishing/luxuryGaming.jpg'
import theFutureOfLuxuryBrands from '@/assets/img/publishing/theFutureOfLuxuryBrands.png'
import luxuryBrands from '@/assets/img/publishing/luxuryBrands.jpg'
import breaking from '@/assets/img/publishing/breakingTheParadigms.png'
import badHombre from '@/assets/img/publishing/badHombre.png'
import shockvertising from '@/assets/img/publishing/shockvertising.png'
import emotions from '@/assets/img/publishing/emotions.png'
import artificialIntelligence from '@/assets/img/publishing/artificial-intelligence.png'
import shortage from '@/assets/img/publishing/shortage.jpg'
import emotionsAi from '@/assets/img/publishing/emotionsAi.jpg'
import goldenAgeOfSports from '@/assets/img/publishing/goldenAgeOfSports.png'
import elMundo from '@/assets/img/publishing/elMundo.png'

export default [
  { title: 'luxuryGaming', image: luxuryGaming },
  { title: 'theFutureOfLuxuryBrands', image: theFutureOfLuxuryBrands },
  { title: 'luxuryBrands', image: luxuryBrands },
  { title: 'elMundo', image: elMundo },
  { title: 'breaking', image: breaking },
  { title: 'badHombre', image: badHombre },
  { title: 'shockvertising', image: shockvertising },
  { title: 'emotions', image: emotions },
  { title: 'artificialIntelligence', image: artificialIntelligence },
  { title: 'shortage', image: shortage },
  { title: 'emotionsAi', image: emotionsAi },
  { title: 'goldenAgeOfSports', image: goldenAgeOfSports },
]
