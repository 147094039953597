<template>
  <img ref="lazyLoadImage" :class="className" :src="placeholder" />
</template>

<script>
import { lazyLoadImages } from '@/assets/js/lazy-load'

import { GRAY_PLACEHOLDER } from '../shared/constants'

export default {
  name: 'LazyLoadImage',
  props: ['class-name'],
  data: () => ({
    placeholder: GRAY_PLACEHOLDER
  }),
  mounted() {
    this.lazyLoadDispose = lazyLoadImages([this.$refs.lazyLoadImage])
  },
  unmounted() {
    this.lazyLoadDispose && this.lazyLoadDispose()
  },
}
</script>
