<template>
  <article class="trend-report" :class="componentClass">
    <div class="bg-gray-50">
      <LazyLoadImage
        :data-src="image"
        alt="Trend Report"
        class="h-52 w-full object-cover cursor-pointer"
        @click="$emit('click')"
      />
      <div class="flex flex-col items-center justify-center h-48">
        <span class="text-xs text-gray-600 uppercase mb-3">{{
          $t(`reports.${title}.date`)
        }}</span>
        <p
          class="text-xl font-bold mb-3 text-center"
          v-html="$t(`reports.${title}.name`)"
        ></p>
        <span
          class="text-xs text-gray-600 underline cursor-pointer"
          @click="$emit('click')"
          >{{ $t('reports.readMore') }}</span
        >
      </div>
    </div>
  </article>
</template>

<script type="text/javascript">
import LazyLoadImage from '../components/LazyLoadImage.vue';

export default {
  name: 'TrendReport',
  components: { LazyLoadImage },
  props: ['component-class', 'title', 'image'],
}
</script>

<style>
.trend-report {
  margin-bottom: 5.375rem;
  padding-right: 0.938rem;
  padding-left: 0.938rem;
}
</style>
