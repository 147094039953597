<template>
  <div class="flex justify-center flex-wrap -mb-22">
    <trend-report
      v-for="section in reports"
      :key="section.id"
      component-class="enter-home enter-fade-up
            md:w-1/3
            w-full"
      :title="section.title"
      :image="section.image"
      @click="gotoReports(section.id)"
    ></trend-report>
  </div>
</template>

<script>
// Components
import TrendReport from '@/components/TrendReport.vue'

// JSON
import reports from '@/assets/js/reports.js'

export default {
  name: 'ReportsSection',
  components: { TrendReport },
  data: () => ({ reports }),
  methods: {
    gotoReports(year) {
      this.$router.push(`/reports/${year}`)
    },
  },
}
</script>
