<template>
  <!-- Hero -->
  <section class="h-screen bg-black relative overflow-hidden">
    <!-- <div class="relative h-full z-40
      md:block
      flex items-center justify-center">
      <video ref="video" id="hero-video" class="w-full object-cover
        md:h-full
        h-1/2" :muted="muted">
        <source src="@/assets/vid/home-video.mp4" type="video/mp4">
      </video>
      <button class="absolute right-8 bottom-8 w-20 h-20"
        @click="muted = !muted">
        <img :src="muted ? iconMuted : iconUnmuted" alt="Volume"
          class="w-full h-full object-contain">
      </button>
    </div> -->

    <div
      class="hero h-full w-full bg-black absolute top-0 flex flex-col justify-center z-50"
      :class="{ show: showHero }"
    >
      <div
        class="enter-fade-down delay-700
        xl:ml-48
        lg:ml-36
        md:ml-28
        ml-12"
        :class="{ show: showHero }"
      >
        <div
          class="relative flex items-center mb-8 text-white
          text-sm md:text-base"
        >
          <div class="title-bar title-bar-white"></div>
          <span>TRENDS LAB</span>
        </div>
        <h1
          class="hero-title relative text-white font-bold
          xl:text-8xl
          lg:text-7xl
          md:text-5xl
          text-3xl"
          v-html="$t('home.hero')"
        ></h1>
      </div>
      <img
        src="@/assets/img/arrow-down.svg"
        alt="Follow scroll"
        class="follow-scroll cursor-pointer"
        :class="{ hidden: !showHero }"
        @click="nextFrame()"
      />
    </div>
  </section>
</template>

<script>
// Redux
import { store } from '../main'

export default {
  name: 'Hero',
  watch: {
    'sharedState.routerAnimationEnd': function(newValue) {
      if (newValue) {
        this.showHero = true
      }
    },
  },
  data: () => ({
    showHero: false,
    sharedState: store.state,
  }),
  methods: {
    nextFrame() {
      window.scrollBy({
        top: window.innerHeight,
        behavior: 'smooth',
      })
    },
  },
  mounted() {
    // hero video

    if (store.state.routerAnimationEnd) {
      this.showHero = true
    }
  },
}
</script>

<style scoped>
.hero::before {
  @apply absolute top-0 left-0 h-full w-full bg-cover transition-all ease-in-out duration-700 delay-700;
  content: '';
  background: url('../assets/img/hero-back.jpg') no-repeat center center;
  background-size: cover;
  opacity: 0;
  transform: translateY(-40px);
  filter: brightness(0.5);
}

.hero.show::before {
  opacity: 1;
  transform: none;
}

.hero-title {
  min-width: calc(100vw - 12rem);
}

@keyframes follow {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  10% {
    opacity: 1;
    transform: translateY(-100%);
  }
  80% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 0;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.follow-scroll {
  position: absolute;
  bottom: 2rem;
  left: calc(50% - 1.25rem);
  width: 2.5rem;
  /* initial state */
  opacity: 0;
  transform: translateY(-100%);
  animation-name: follow;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}
</style>
