<template>
  <div ref="fullwatch" class="fullwatch" @click="dismiss">
    <img
      ref="fullwatchImage"
      :src="image"
      :alt="title"
      class="fullwatch-image"
    />
  </div>
</template>

<script>
export default {
  name: 'FullWatch',
  props: ['image'],
  emits: ['dismiss'],
  watch: {
    image(nw) {
      if (nw) {
        this.$refs.fullwatch.classList.add('active')
        setTimeout(() => {
          this.$refs.fullwatchImage.classList.add('active')
        }, 300)
      }
    },
  },
  methods: {
    dismiss() {
      this.$refs.fullwatchImage.classList.remove('active')
      setTimeout(() => {
        this.$refs.fullwatch.classList.remove('active')
        this.$emit('dismiss')
      }, 300)
    },
  },
}
</script>

<style scoped>
.fullwatch {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.8);
  transform: scaleX(0);
  transform-origin: center left;
  transition: transform 0.3s ease;
  will-change: transform;
}
.fullwatch.active {
  transform: scaleX(1);
}
.fullwatch-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.3s ease;
  will-change: opacity;
}
.fullwatch-image.active {
  opacity: 1;
}
</style>
