// Obtenido de: https://www.w3docs.com/snippets/javascript/lazy-load-images-with-javascript.html
export const lazyLoad = (lazyLoadElements) => {
  if ('IntersectionObserver' in window) {
    let elementObserver = new IntersectionObserver(function(entries) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          let element = entry.target
          if (element.dataset.background)
            element.style.setProperty('background', `url('${element.dataset.background}')`)
          else {
            element.src = element.dataset.src
          }
          elementObserver.unobserve(element)
        }
      })
    })
    lazyLoadElements.forEach(element => {
      elementObserver.observe(element)
    })
  } else {
    let lazyloadThrottleTimeout

    const lazyLoadFunctions = []

    const lazyload = () => {
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout)
      }
      lazyloadThrottleTimeout = setTimeout(() => {
        let scrollTop = window.pageYOffset
        lazyLoadElements.forEach((element) => {
          if (element.offsetTop < (window.innerHeight + scrollTop)) {
            if (element.dataset.background)
              element.style.setProperty('background', `url('${element.dataset.background}')`)
            else
              element.src = element.dataset.src
          }
        })
        if (lazyLoadElements.length == 0) {
          document.removeEventListener('scroll', lazyload)
          window.removeEventListener('resize', lazyload)
          window.removeEventListener('orientationChange', lazyload)
        }
      }, 20)
    }

    lazyLoadFunctions.push(lazyload)

    document.addEventListener('scroll', lazyload)
    window.addEventListener('resize', lazyload)
    window.addEventListener('orientationChange', lazyload)

    return function() {
      lazyLoadFunctions.forEach(lazy => {
        document.addEventListener('scroll', lazy)
        window.addEventListener('resize', lazy)
        window.addEventListener('orientationChange', lazy)
      })
    }
  }
}

export const lazyLoadImages = (images) => lazyLoad(images)