<template>
  <section ref="carousel" class="carousel">
    <div
      ref="wrapper"
      class="carousel-wrapper"
      :style="{
        transform: `translateX(${initialTranslateX}px)`,
      }"
    >
      <carousel-item
        v-for="(item, index) in displayItems"
        :key="item"
        :title="title"
        :subtitle="subtitle"
        :image="items[item]"
        :active="index === current"
        @mouseenter="clearAuto()"
        @mouseleave="setAuto()"
        @image-selected="$emit('image-selected', $event)"
      >
      </carousel-item>
    </div>
    <div class="arrow-container arrow-left" @click="focus(current - 1, false)">
      <img
        class="arrow"
        src="@/assets/img/arrow-right.svg"
        alt="Previous slide"
      />
    </div>
    <div class="arrow-container arrow-right" @click="focus(current + 1, false)">
      <img class="arrow" src="@/assets/img/arrow-right.svg" alt="Next slide" />
    </div>
  </section>
</template>

<script>
import CarouselItem from './CarouselItem.vue'

export default {
  name: 'Carousel',
  components: { CarouselItem },
  props: ['items', 'title', 'subtitle'],
  emits: ['image-selected', 'element-autoslided'],
  data: () => ({
    current: 2,
    displayItems: [],
    // reorder element on resize
    windowWidth: window.innerWidth,
    wrapperWidth: Math.min(1440, document.body.offsetWidth),
  }),
  computed: {
    itemWidth() {
      if (this.windowWidth < 1024) return this.wrapperWidth
      const computedWidth = this.windowWidth * 0.55
      return computedWidth <= 760 ? computedWidth : 760
    },
    initialTranslateX() {
      return (
        -this.current * this.itemWidth -
        this.itemWidth / 2 +
        this.wrapperWidth / 2
      )
    },
  },
  methods: {
    focus(index, auto = true) {
      // primero agregamos elementos al carrusel
      if (index < 2) {
        this.$refs.wrapper.classList.add('no-transition')
        while (index < 2) {
          let n = this.displayItems[0] - 1
          if (n < 0) n += this.items.length
          this.displayItems.unshift(n)
          this.current++
          index++
        }
        setTimeout(() => this.$refs.wrapper.classList.remove('no-transition'))
      } else
        while (index > this.displayItems.length - 2) {
          this.displayItems.push(
            (this.displayItems[this.displayItems.length - 1] + 1) %
              this.items.length
          )
        }

      // luego se desliza hacia current
      setTimeout(() => {
        this.current = index
        auto && this.$emit('element-autoslided')
      })
    },

    setAuto() {
      this.clearAuto()
      this.carouselInterval = setInterval(
        () => this.focus(this.current + 1),
        4000
      )
    },

    clearAuto() {
      clearInterval(this.carouselInterval)
    },
  },
  created() {
    for (let j = 0; j < 5; j++) {
      this.displayItems.push(j % this.items.length)
    }
  },
  mounted() {
    this.setAuto()
    // update element on resize
    this.resizeEventListener = () => {
      this.windowWidth = window.innerWidth
      this.wrapperWidth = Math.min(1440, document.body.offsetWidth)
    }
    window.addEventListener('resize', this.resizeEventListener)
  },
  unmounted() {
    this.clearAuto()
    window.removeEventListener('resize', this.resizeEventListener)
  },
}
</script>

<style scoped>
.carousel {
  height: 544px;
  width: 100%;
  background: black;
  overflow: hidden;
  position: relative;
}
.carousel-wrapper {
  height: 100%;
  transition: transform 0.5s ease;
  white-space: nowrap;
  /*avoid whitespace between carousel items*/
  font-size: 0;
  will-change: transform;
}
.no-transition {
  transition: none;
}
.arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20%;
  height: 60%;
  width: 22.5vw;
  min-width: 5rem;
  max-width: calc(50vw - 380px);
  cursor: pointer;
}
.arrow {
  width: 26px;
  height: 26px;
  object-fit: contain;
}
.arrow-container.arrow-left {
  left: 0;
  transform: rotate(180deg);
}
.arrow-container.arrow-right {
  right: 0;
}
@media (min-width: 768px) {
  .arrow-container.arrow-left {
    left: 64px;
  }
  .arrow-container.arrow-right {
    right: 64px;
  }
}
</style>
