<template>
  <div class="gallery-container">
    <div
      class="gallery-wrapper"
      :style="{
        transform: `translateY(-${544 * currentGallery}px)`,
      }"
    >
      <carousel
        v-for="(gallery, index) in galleries"
        :key="index"
        :title="gallery.title"
        :subtitle="gallery.subtitle"
        :items="gallery.images"
        @image-selected="fullwatchSelected = $event"
        @element-autoslided="index === currentGallery && handleSlideCount()"
      >
      </carousel>
    </div>
  </div>
  <!-- full watch-->
  <full-watch :image="fullwatchSelected" @dismiss="fullwatchSelected = ''">
  </full-watch>
  <div class="flex justify-center items-center mt-8">
    <span
      class="gallery-bullet"
      :class="{
        active: index === currentGallery,
      }"
      v-for="(gallery, index) in galleries"
      :key="index"
      @click="setCurrentGallery(index)"
    ></span>
  </div>
</template>

<script>
// Components
import Carousel from "@/components/Carousel.vue";
import FullWatch from '@/components/FullWatch.vue'

// CHIVAS REGAL - Materialization
import chivasMaterialization1 from "@/assets/img/chivas-materialization/chivas1.png";
import chivasMaterialization2 from "@/assets/img/chivas-materialization/chivas2.png";
import chivasMaterialization3 from "@/assets/img/chivas-materialization/chivas3.png";
import chivasMaterialization4 from "@/assets/img/chivas-materialization/chivas4.png";
import chivasMaterialization5 from "@/assets/img/chivas-materialization/chivas5.png";

// DIGITAL LEGACY - Conference in Luxurylab
import digitalLegacy1 from "@/assets/img/digital-legacy/digital1.jpg";
import digitalLegacy2 from "@/assets/img/digital-legacy/digital2.jpg";
import digitalLegacy3 from "@/assets/img/digital-legacy/digital3.jpg";


// HYPERLOCAL BY LEVI'S - Collection
import levis1 from "@/assets/img/levis/levis1.jpg";
import levis2 from "@/assets/img/levis/levis2.jpg";
import levis3 from "@/assets/img/levis/levis3.jpg";

// ANGEL SOUL - Mexico city cocktail
import angel1 from "@/assets/img/angel/angel1.png";
import angel2 from "@/assets/img/angel/angel2.png";
import angel3 from "@/assets/img/angel/angel3.png";
import angel4 from "@/assets/img/angel/angel4.png";

//BREAKING THE PARADIGMS OF
//EXPRESSION: LANGUAGE & NEW BEAUTY - NEW MAGAZINE ARTICLE
import breaking1 from "@/assets/img/breaking/breaking1.png";
import breaking2 from "@/assets/img/breaking/breaking2.png";
import breaking3 from "@/assets/img/breaking/breaking3.png";

export default {
  name: "Gallery",
  components: { Carousel, FullWatch },
  data: () => ({
    galleries: [
      {
        title: "Chivas Regal",
        subtitle: "Materialization",
        images: [
          chivasMaterialization1,
          chivasMaterialization2,
          chivasMaterialization3,
          chivasMaterialization4,
          chivasMaterialization5,
        ],
      },
      {
        title: "Hyperlocal by Levi's",
        subtitle: "Collection",
        images: [levis1, levis2, levis3],
      },
      {
        title: "Angel Soul",
        subtitle: "Mexico City Cocktail",
        images: [angel1, angel2, angel3, angel4],
      },
      {
        title:
          "Breaking the paradigms of<br/>expression: Language & new beauty",
        subtitle: "Neo magazine article",
        images: [breaking1, breaking2, breaking3],
      },
      {
        title: "Digital Legacy",
        subtitle: "Conference at Luxurylab",
        images: [digitalLegacy1, digitalLegacy2, digitalLegacy3],
      },
    ],

    fullwatchSelected: '',
    currentGallery: 0,
    slideCount: 0
  }),
  methods: {
    nextGallery() {
      this.currentGallery = (this.currentGallery + 1) % this.galleries.length
    },
    setCurrentGallery(index) {
      this.currentGallery = index
      this.slideCount = 0
    },
    handleSlideCount() {
      this.slideCount = this.slideCount + 1
      if (this.slideCount === this.galleries[this.currentGallery].images.length) {
        this.slideCount = 0
        setTimeout(() => this.nextGallery())
      }
    }
  }
};
</script>

<style>
.gallery-bullet {
  display: inline-block;
  border-radius: 1000px;
  background-color: #494949;
  width: 1rem;
  height: 1rem;
  transition: transform, background-color 0.4s ease;
  will-change: transform, background-color;
  margin-right: 1.5rem;
  cursor: pointer
}
.gallery-bullte:last-child {
  margin-right: 0;
}
.gallery-bullet.active {
  transform: scale(1.5);
  background-color: black
}
.gallery-container {
  height: 544px;
  width: 100%;
  background: black;
  overflow: hidden;
}
.gallery-wrapper {
  width: 100%;
  transition: transform 0.5s ease;
  will-change: transform;
}
</style>
