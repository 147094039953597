<template>
  <carousel
    :items-to-show="1"
    :autoplay="4000"
    :breakpoints="breakpoints"
    :wrap-around="true"
    snap-align="start"
  >
    <slide v-for="publish in publishing" :key="publish.title">
      <TrendReport
        component-class="enter-home enter-fade-up w-full"
        :title="publish.title"
        :image="publish.image"
        @click="handlePublishClick(publish)"
      />
    </slide>
    <template #addons>
      <navigation />
    </template>
  </carousel>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'

// Components
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import TrendReport from './TrendReport.vue'

// Assets
import publishing from '../assets/js/publishing'

// Store
import { store } from '../main'

export default {
  name: 'PublishingCarousel',
  components: { Carousel, Slide, Navigation, TrendReport },
  data: () => ({
    publishing,
    breakpoints: {
      700: { itemsToShow: 2 },
      1024: { itemsToShow: 3 },
    },
    sharedState: store.state,
  }),
  methods: {
    handleRouteChange(newValue) {
      const { file } = newValue
      store.showFile(file && `/pdf/${file}`)
    },
    handlePublishClick(publish) {
      let file = this.$t(`reports.${publish.title}.file`)
      if (file.startsWith('http')) {
        window.open(file, '_blank')
      } else {
        file = file.replace('/pdf/', '')
        this.$router.push(`/${file}`)
      }
    },
  },
  watch: {
    '$route.params': function(newValue) {
      this.handleRouteChange(newValue)
    },
    'sharedState.file': function(newValue) {
      if (!newValue) {
        this.$router.push('/')
      }
    },
  },
  mounted() {
    this.handleRouteChange(this.$route.params)
  },
}
</script>
