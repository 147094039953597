<template>
  <div class="marquee-container">
    <h1
      class="
      xl:text-8xl
      lg:text-7xl
      md:text-5xl
      text-3xl"
      v-html="text"
    ></h1>
    <h1
      class="
      xl:text-8xl
      lg:text-7xl
      md:text-5xl
      text-3xl"
      v-html="text"
    ></h1>
  </div>
</template>

<script>
export default {
  name: 'CustomMarquee',
  props: ['text'],
}
</script>

<style>
.marquee-container {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
.marquee-container > h1 {
  display: inline-block;
  animation-name: marquee;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
