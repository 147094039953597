<template>
  <div ref="container">
    <svg v-if="$i18n.locale === 'en'" class="distortion-svg" width="1011" height="690.357" version="1.1" viewBox="0 0 267.493 182.657" xmlns="http://www.w3.org/2000/svg">
      <text transform="scale(.26458)" fill="#000000" font-family="sans-serif" font-size="40px" style="line-height:1.25;shape-inside:url(#rect5557);white-space:pre" xml:space="preserve"/>
      <g stroke-width=".264583" aria-label="At Trends Lab  we continuously  listen to the  heartbeat of the  society to detect  future trends.">
        <path d="m17.526 18.0022h-11.811l-2.44475 5.55625h-3.27025l10.0648-22.225h3.14325l10.0965 22.225h-3.33375zm-1.11125-2.54-4.79425-10.8902-4.79425 10.8902z"/>
        <path d="m35.4647 22.5742q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.36525t-1.397-3.90525v-9.23925h-2.8575v-2.50825h2.8575v-3.683h3.048v3.683h4.826v2.50825h-4.826v9.11225q0 1.36525 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.38125-0.79375z"/>
        <path d="m51.7524 6.31825h-6.82625v-4.98475h19.9072v4.98475h-6.7945v17.2402h-6.2865z"/>
        <path d="m72.2945 8.41375q0.92075-1.17475 2.38125-1.74625 1.49225-0.60325 3.39725-0.60325v5.42925q-0.8255-0.09525-1.36525-0.09525-1.93675 0-3.048 1.04775-1.0795 1.04775-1.0795 3.20675v7.90575h-6.0325v-17.2085h5.74675z"/>
        <path d="m97.9802 14.986q0 0.0635-0.09525 1.49225h-12.5095q0.381 1.3335 1.4605 2.06375 1.11125 0.6985 2.76225 0.6985 1.23825 0 2.12725-0.34925 0.92075-0.34925 1.8415-1.143l3.175 3.302q-2.50825 2.794-7.33425 2.794-3.01625 0-5.30225-1.143t-3.556-3.175q-1.23825-2.032-1.23825-4.60375 0-2.54 1.2065-4.54025 1.23825-2.032 3.39725-3.175t4.85775-1.143q2.57175 0 4.66725 1.0795 2.0955 1.04775 3.302 3.07975 1.23825 2.00025 1.23825 4.7625zm-9.17575-4.6355q-1.397 0-2.3495 0.79375-0.92075 0.79375-1.17475 2.159h7.0485q-0.254-1.36525-1.2065-2.159-0.92075-0.79375-2.31775-0.79375z"/>
        <path d="m111.982 6.06425q3.20675 0 5.1435 1.905 1.9685 1.905 1.9685 5.74675v9.8425h-6.0325v-8.85825q0-3.52425-2.82575-3.52425-1.55575 0-2.50825 1.016-0.92075 1.016-0.92075 3.048v8.3185h-6.0325v-17.2085h5.74675v1.87325q1.016-1.04775 2.413-1.5875 1.397-0.5715 3.048-0.5715z"/>
        <path d="m141.414 2.89917e-7v23.5585h-5.74675v-1.7145q-1.68275 2.00025-5.04825 2.00025-2.3495 0-4.28625-1.0795-1.93675-1.11125-3.07975-3.14325-1.11125-2.032-1.11125-4.699t1.11125-4.66725q1.143-2.032 3.07975-3.1115t4.28625-1.0795q3.07975 0 4.7625 1.778v-7.84225zm-9.525 19.05q1.55575 0 2.57175-1.0795 1.016-1.11125 1.016-3.048 0-1.905-1.016-2.9845-0.98425-1.0795-2.57175-1.0795t-2.6035 1.0795-1.016 2.9845q0 1.93675 1.016 3.048 1.016 1.0795 2.6035 1.0795z"/>
        <path d="m151.542 23.8442q-2.159 0-4.2545-0.47625-2.0955-0.508-3.3655-1.30175l1.80975-4.1275q1.17475 0.73025 2.794 1.17475 1.61925 0.41275 3.20675 0.41275 1.4605 0 2.06375-0.28575 0.635-0.3175 0.635-0.889t-0.762-0.79375q-0.73025-0.254-2.3495-0.47625-2.06375-0.254-3.52425-0.66675-1.42875-0.41275-2.4765-1.524t-1.04775-3.1115q0-1.651 0.98425-2.921 0.98425-1.30175 2.8575-2.032 1.905-0.762 4.54025-0.762 1.87325 0 3.71475 0.381t3.07975 1.0795l-1.80975 4.09575q-2.286-1.27-4.953-1.27-1.42875 0-2.0955 0.34925-0.66675 0.3175-0.66675 0.85725 0 0.60325 0.73025 0.85725 0.73025 0.22225 2.413 0.47625 2.12725 0.3175 3.52425 0.73025t2.413 1.524q1.04775 1.0795 1.04775 3.048 0 1.61925-0.98425 2.921-0.98425 1.27-2.921 2.00025-1.905 0.73025-4.60375 0.73025z"/>
        <path d="m172.18 1.3335h6.2865v17.2402h10.6045v4.98475h-16.891z"/>
        <path d="m198.85 6.06425q4.28625 0 6.5405 1.9685 2.25425 1.93675 2.25425 5.969v9.55675h-5.61975v-2.2225q-1.30175 2.50825-5.08 2.50825-2.00025 0-3.46075-0.6985t-2.2225-1.87325q-0.73025-1.2065-0.73025-2.7305 0-2.44475 1.87325-3.77825 1.87325-1.36525 5.7785-1.36525h3.429q-0.15875-2.7305-3.65125-2.7305-1.23825 0-2.50825 0.41275-1.27 0.381-2.159 1.0795l-2.032-4.09575q1.42875-0.9525 3.46075-1.4605 2.06375-0.53975 4.1275-0.53975zm-0.22225 13.97q1.04775 0 1.8415-0.508t1.143-1.49225v-1.49225h-2.6035q-2.63525 0-2.63525 1.74625 0 0.79375 0.60325 1.27t1.651 0.47625z"/>
        <path d="m222.281 6.06425q2.3495 0 4.2545 1.0795 1.93675 1.0795 3.048 3.1115 1.143 2.00025 1.143 4.66725t-1.143 4.699q-1.11125 2.032-3.048 3.14325-1.905 1.0795-4.2545 1.0795-3.39725 0-5.08-2.00025v1.7145h-5.74675v-23.5585h6.0325v7.84225q1.74625-1.778 4.79425-1.778zm-1.27 12.9857q1.5875 0 2.6035-1.0795 1.016-1.11125 1.016-3.048 0-1.905-1.016-2.9845t-2.6035-1.0795-2.6035 1.0795-1.016 2.9845q0 1.93675 1.016 3.048 1.016 1.0795 2.6035 1.0795z"/>
        <path d="m28.3845 38.5126-6.2865 16.8275h-2.921l-4.85775-12.7635-4.92125 12.7635h-2.921l-6.25475-16.8275h2.88925l4.8895 13.3985 5.08-13.3985h2.57175l4.98475 13.462 5.0165-13.462h2.7174z"/>
        <path d="m46.1962 47.0216q0 0.34925-0.0635 0.92075h-13.6525q0.28575 2.2225 1.93675 3.58775 1.68275 1.3335 4.15925 1.3335 3.01625 0 4.85775-2.032l1.68275 1.9685q-1.143 1.3335-2.8575 2.032-1.68275 0.6985-3.77825 0.6985-2.667 0-4.73075-1.0795-2.06375-1.11125-3.20675-3.07975-1.11125-1.9685-1.11125-4.445 0-2.44475 1.0795-4.41325 1.11125-1.9685 3.01625-3.048 1.93675-1.11125 4.34975-1.11125t4.28625 1.11125q1.905 1.0795 2.95275 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.19075 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.12725-1.74625-3.46075-1.4605-1.36525-3.65125-1.36525z"/>
        <path d="m66.2939 55.5306q-2.57175 0-4.60375-1.11125-2.00025-1.11125-3.14325-3.048-1.143-1.9685-1.143-4.445t1.143-4.41325q1.143-1.9685 3.14325-3.048 2.032-1.11125 4.60375-1.11125 2.286 0 4.064 0.92075 1.80975 0.92075 2.794 2.667l-2.31775 1.49225q-0.79375-1.2065-1.9685-1.80975t-2.6035-0.60325q-1.651 0-2.9845 0.73025-1.30175 0.73025-2.06375 2.0955-0.73025 1.3335-0.73025 3.07975 0 1.778 0.73025 3.14325 0.762 1.3335 2.06375 2.06375 1.3335 0.73025 2.9845 0.73025 1.42875 0 2.6035-0.60325t1.9685-1.80975l2.31775 1.4605q-0.98425 1.74625-2.794 2.69875-1.778 0.92075-4.064 0.92075z"/>
        <path d="m84.042 55.5306q-2.50825 0-4.5085-1.11125t-3.14325-3.048q-1.11125-1.9685-1.11125-4.445t1.11125-4.41325q1.143-1.9685 3.14325-3.048 2.00025-1.11125 4.5085-1.11125t4.47675 1.11125q2.00025 1.0795 3.1115 3.048 1.143 1.93675 1.143 4.41325t-1.143 4.445q-1.11125 1.93675-3.1115 3.048-1.9685 1.11125-4.47675 1.11125zm0-2.667q1.61925 0 2.88925-0.73025 1.30175-0.762 2.032-2.0955 0.73025-1.36525 0.73025-3.1115t-0.73025-3.07975q-0.73025-1.36525-2.032-2.0955-1.27-0.73025-2.88925-0.73025t-2.921 0.73025q-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.07975t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.30175 0.73025 2.921 0.73025z"/>
        <path d="m105.981 38.3539q3.20675 0 5.08 1.87325 1.905 1.8415 1.905 5.42925v9.68375h-3.048v-9.3345q0-2.44475-1.17475-3.683t-3.3655-1.23825q-2.4765 0-3.90525 1.4605-1.42875 1.42875-1.42875 4.1275v8.66775h-3.048v-16.8275h2.921v2.54q0.920751-1.30175 2.4765-2.00025 1.5875-0.6985 3.58775-0.6985z"/>
        <path d="m128.079 54.3559q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.36525t-1.397-3.90525v-9.23925h-2.8575v-2.50825h2.8575v-3.683h3.048v3.683h4.826v2.50825h-4.826v9.11225q0 1.36525 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.38125-0.79375z"/>
        <path d="m131.762 38.5126h3.048v16.8275h-3.048zm1.524-3.2385q-0.889 0-1.49225-0.5715-0.5715-0.5715-0.5715-1.397t0.5715-1.397q0.60325-0.60325 1.49225-0.60325t1.4605 0.5715q0.60325 0.53975 0.60325 1.36525 0 0.85725-0.60325 1.4605-0.5715 0.5715-1.4605 0.5715z"/>
        <path d="m149.606 38.3539q3.20675 0 5.08 1.87325 1.905 1.8415 1.905 5.42925v9.68375h-3.048v-9.3345q0-2.44475-1.17475-3.683t-3.3655-1.23825q-2.4765 0-3.90525 1.4605-1.42875 1.42875-1.42875 4.1275v8.66775h-3.048v-16.8275h2.921v2.54q0.92075-1.30175 2.4765-2.00025 1.5875-0.6985 3.58775-0.6985z"/>
        <path d="m177.927 38.5126v16.8275h-2.88925v-2.54q-0.92075 1.30175-2.44475 2.032-1.49225 0.6985-3.27025 0.6985-3.3655 0-5.30225-1.8415-1.93675-1.87325-1.93675-5.49275v-9.68375h3.048v9.3345q0 2.44475 1.17475 3.71475 1.17475 1.23825 3.3655 1.23825 2.413 0 3.81-1.4605t1.397-4.1275v-8.6995z"/>
        <path d="m190.944 55.5306q-2.50825 0-4.5085-1.11125t-3.14325-3.048q-1.11125-1.9685-1.11125-4.445t1.11125-4.41325q1.143-1.9685 3.14325-3.048 2.00025-1.11125 4.5085-1.11125t4.47675 1.11125q2.00025 1.0795 3.1115 3.048 1.143 1.93675 1.143 4.41325t-1.143 4.445q-1.11125 1.93675-3.1115 3.048-1.9685 1.11125-4.47675 1.11125zm0-2.667q1.61925 0 2.88925-0.73025 1.30175-0.762 2.032-2.0955 0.73025-1.36525 0.73025-3.1115t-0.73025-3.07975q-0.73025-1.36525-2.032-2.0955-1.27-0.73025-2.88925-0.73025t-2.921 0.73025q-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.07975t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.30175 0.73025 2.921 0.73025z"/>
        <path d="m219.583 38.5126v16.8275h-2.88925v-2.54q-0.92075 1.30175-2.44475 2.032-1.49225 0.6985-3.27025 0.6985-3.3655 0-5.30225-1.8415-1.93675-1.87325-1.93675-5.49275v-9.68375h3.048v9.3345q0 2.44475 1.17475 3.71475 1.17475 1.23825 3.3655 1.23825 2.413 0 3.81-1.4605t1.397-4.1275v-8.6995z"/>
        <path d="m230.219 55.5306q-2.06375 0-3.96875-0.53975-1.905-0.5715-2.9845-1.42875l1.27-2.413q1.11125 0.79375 2.69875 1.27t3.20675 0.47625q4.0005 0 4.0005-2.286 0-0.762-0.53975-1.2065t-1.36525-0.635q-0.79375-0.22225-2.286-0.47625-2.032-0.3175-3.33375-0.73025-1.27-0.41275-2.19075-1.397t-0.92075-2.76225q0-2.286 1.905-3.65125 1.905-1.397 5.11175-1.397 1.68275 0 3.3655 0.41275t2.76225 1.11125l-1.30175 2.413q-2.06375-1.3335-4.85775-1.3335-1.93675 0-2.95275 0.635t-1.016 1.68275q0 0.8255 0.5715 1.30175t1.397 0.6985q0.85725 0.22225 2.38125 0.508 2.032 0.34925 3.27025 0.762 1.27 0.381 2.159 1.3335t0.889 2.667q0 2.286-1.9685 3.65125-1.93675 1.3335-5.30225 1.3335z"/>
        <path d="m241.299 31.7816h3.048v23.5585h-3.048z"/>
        <path d="m265.048 38.5126-8.15975 18.5102c-0.71967 1.7145-1.56633 2.921-2.54 3.6195-0.9525 0.6985-2.10608 1.04775-3.46075 1.04775-0.8255 0-1.62983-0.137583-2.413-0.41275-0.762-0.254-1.397-0.635-1.905-1.143l1.30175-2.286c0.86783 0.8255 1.87325 1.23825 3.01625 1.23825 0.74083 0 1.35467-0.201083 1.8415-0.60325 0.508-0.381 0.96308-1.04775 1.36525-2.00025l0.53975-1.17475-7.4295-16.7958h3.175l5.87375 13.462 5.81025-13.462h2.97205z"/>
        <path d="m2.921 63.5632h3.048v23.5585h-3.048z"/>
        <path d="m11.7792 70.2942h3.048v16.8275h-3.048zm1.524-3.2385q-0.889 0-1.49225-0.5715-0.5715-0.5715-0.5715-1.397t0.5715-1.397q0.60325-0.60325 1.49225-0.60325t1.4605 0.5715q0.60325 0.53975 0.60325 1.36525 0 0.85725-0.60325 1.4605-0.5715 0.5715-1.4605 0.5715z"/>
        <path d="m25.4635 87.3122q-2.06375 0-3.96875-0.53975-1.905-0.5715-2.9845-1.42875l1.27-2.413q1.11125 0.79375 2.69875 1.27t3.20675 0.47625q4.0005 0 4.0005-2.286 0-0.762-0.53975-1.2065t-1.36525-0.635q-0.79375-0.22225-2.286-0.47625-2.032-0.3175-3.33375-0.73025-1.27-0.41275-2.19075-1.397t-0.92075-2.76225q0-2.286 1.905-3.65125 1.905-1.397 5.11175-1.397 1.68275 0 3.3655 0.41275t2.76225 1.11125l-1.30175 2.413q-2.06375-1.3335-4.85775-1.3335-1.93675 0-2.95275 0.635t-1.016 1.68275q0 0.8255 0.5715 1.30175t1.397 0.6985q0.85725 0.22225 2.38125 0.508 2.032 0.34925 3.27025 0.762 1.27 0.381 2.159 1.3335t0.889 2.667q0 2.286-1.9685 3.65125-1.93675 1.3335-5.30225 1.3335z"/>
        <path d="m46.0057 86.1375q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.36525t-1.397-3.90525v-9.23925h-2.8575v-2.50825h2.8575v-3.683h3.048v3.683h4.826v2.50825h-4.826v9.11225q0 1.36525 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.38125-0.79375z"/>
        <path d="m64.3254 78.8032q0 0.34925-0.0635 0.92075h-13.6525q0.28575 2.2225 1.93675 3.58775 1.68275 1.3335 4.15925 1.3335 3.01625 0 4.85775-2.032l1.68275 1.9685q-1.143 1.3335-2.8575 2.032-1.68275 0.6985-3.77825 0.6985-2.667 0-4.73075-1.0795-2.06375-1.11125-3.20675-3.07975-1.11125-1.9685-1.11125-4.445 0-2.44475 1.0795-4.41325 1.11125-1.9685 3.01625-3.048 1.93675-1.11125 4.34975-1.11125t4.28625 1.11125q1.905 1.0795 2.95275 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.19075 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.12725-1.74625-3.46075-1.4605-1.36525-3.65125-1.36525z"/>
        <path d="m77.5334 70.1355q3.20675 0 5.08 1.87325 1.905 1.8415 1.905 5.42925v9.68375h-3.048v-9.3345q0-2.44475-1.17475-3.683t-3.3655-1.23825q-2.4765 0-3.90525 1.4605-1.42875 1.42875-1.42875 4.1275v8.66775h-3.048v-16.8275h2.921v2.54q0.92075-1.30175 2.4765-2.00025 1.5875-0.6985 3.58775-0.6985z"/>
        <path d="m108.172 86.1375q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.36525-1.397-1.36525-1.397-3.90525v-9.23925h-2.8575v-2.50825h2.8575v-3.683h3.048v3.683h4.826v2.50825h-4.826v9.11225q0 1.36525 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.38125-0.79375z"/>
        <path d="m118.491 87.3122q-2.50825 0-4.5085-1.11125t-3.14325-3.048q-1.11125-1.9685-1.11125-4.445t1.11125-4.41325q1.143-1.9685 3.14325-3.048 2.00025-1.11125 4.5085-1.11125t4.47675 1.11125q2.00025 1.0795 3.1115 3.048 1.143 1.93675 1.143 4.41325t-1.143 4.445q-1.11125 1.93675-3.1115 3.048-1.9685 1.11125-4.47675 1.11125zm0-2.667q1.61925 0 2.88925-0.73025 1.30175-0.762 2.032-2.0955 0.73025-1.36525 0.73025-3.1115t-0.73025-3.07975q-0.73025-1.36525-2.032-2.0955-1.27-0.73025-2.88925-0.73025t-2.921 0.73025q-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.07975t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.30175 0.73025 2.921 0.73025z"/>
        <path d="m149.447 86.1375q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.36525t-1.397-3.90525v-9.23925h-2.8575v-2.50825h2.8575v-3.683h3.048v3.683h4.826v2.50825h-4.826v9.11225q0 1.36525 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.38125-0.79375z"/>
        <path d="m162.115 70.1355q3.20675 0 5.08 1.87325 1.905 1.8415 1.905 5.42925v9.68375h-3.048v-9.3345q0-2.44475-1.17475-3.683t-3.3655-1.23825q-2.4765 0-3.90525 1.4605-1.42875 1.42875-1.42875 4.1275v8.66775h-3.048v-23.5585h3.048v9.11225q0.9525-1.2065 2.4765-1.87325 1.55575-0.66675 3.46075-0.66675z"/>
        <path d="m190.055 78.8032q0 0.34925-0.0635 0.92075h-13.6525q0.28575 2.2225 1.93675 3.58775 1.68275 1.3335 4.15925 1.3335 3.01625 0 4.85775-2.032l1.68275 1.9685q-1.143 1.3335-2.8575 2.032-1.68275 0.6985-3.77825 0.6985-2.667 0-4.73075-1.0795-2.06375-1.11125-3.20675-3.07975-1.11125-1.9685-1.11125-4.445 0-2.44475 1.0795-4.41325 1.11125-1.9685 3.01625-3.048 1.93675-1.11125 4.34975-1.11125t4.28625 1.11125q1.905 1.0795 2.95275 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.19075 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.12725-1.74625-3.46075-1.4605-1.36525-3.65125-1.36525z"/>
        <path d="m11.9062 101.917q3.20675 0 5.08 1.87325 1.905 1.8415 1.905 5.42925v9.68375h-3.048v-9.3345q0-2.44475-1.17475-3.683t-3.3655-1.23825q-2.4765 0-3.90525 1.4605-1.42875 1.42875-1.42875 4.1275v8.66775h-3.048v-23.5585h3.048v9.11225q0.9525-1.2065 2.4765-1.87325 1.55575-0.66675 3.46075-0.66675z"/>
        <path d="m39.8462 110.585q0 0.34925-0.0635 0.92075h-13.6525q0.28575 2.2225 1.93675 3.58775 1.68275 1.3335 4.15925 1.3335 3.01625 0 4.85775-2.032l1.68275 1.9685q-1.143 1.3335-2.8575 2.032-1.68275 0.6985-3.77825 0.6985-2.667 0-4.73075-1.0795-2.06375-1.11125-3.20675-3.07975-1.11125-1.9685-1.11125-4.445 0-2.44475 1.0795-4.41325 1.11125-1.9685 3.01625-3.048 1.93675-1.11125 4.34975-1.11125t4.28625 1.11125q1.905 1.0795 2.95275 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.19075 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.12725-1.74625-3.46075-1.4605-1.36525-3.65125-1.36525z"/>
        <path d="m49.7204 101.917q3.4925 0 5.334 1.7145 1.87325 1.7145 1.87325 5.11175v10.16h-2.88925v-2.2225q-0.762 1.17475-2.19075 1.80975-1.397 0.60325-3.33375 0.60325-2.82575 0-4.54025-1.36525-1.68275-1.36525-1.68275-3.58775t1.61925-3.556q1.61925-1.36525 5.1435-1.36525h4.826v-0.60325q0-1.9685-1.143-3.01625t-3.3655-1.04775q-1.49225 0-2.921 0.508-1.42875 0.47625-2.413 1.30175l-1.27-2.286q1.30175-1.04775 3.1115-1.5875 1.80975-0.5715 3.84175-0.5715zm-0.6985 14.8272q1.74625 0 3.01625-0.762 1.27-0.79375 1.8415-2.2225v-2.3495h-4.699q-3.8735 0-3.8735 2.6035 0 1.27 0.98425 2.00025t2.7305 0.73025z"/>
        <path d="m65.5001 104.902q0.79375-1.4605 2.3495-2.2225t3.77825-0.762v2.95275q-0.254-0.0317-0.6985-0.0317-2.4765 0-3.90525 1.49225-1.397 1.4605-1.397 4.191v8.382h-3.048v-16.8275h2.921z"/>
        <path d="m85.5343 117.919q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.36525t-1.397-3.90525v-9.23925h-2.8575v-2.50825h2.8575v-3.683h3.048v3.683h4.826v2.50825h-4.826v9.11225q0 1.36525 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.38125-0.79375z"/>
        <path d="m98.1391 101.917q2.44475 0 4.3815 1.0795t3.01625 3.01625q1.11125 1.93675 1.11125 4.47675t-1.11125 4.5085q-1.0795 1.93675-3.01625 3.01625t-4.3815 1.0795q-1.87325 0-3.429-0.73025t-2.57175-2.12725v2.667h-2.921v-23.5585h3.048v9.271q1.016-1.3335 2.54-2.00025 1.524-0.6985 3.33375-0.6985zm-0.254 14.5098q1.61925 0 2.88925-0.73025 1.30175-0.762 2.032-2.0955 0.762-1.36525 0.762-3.1115t-0.762-3.07975q-0.73025-1.36525-2.032-2.0955-1.27-0.73025-2.88925-0.73025-1.5875 0-2.88925 0.73025t-2.032 2.0955q-0.73025 1.3335-0.73025 3.07975t0.73025 3.1115q0.73025 1.3335 2.032 2.0955 1.30175 0.73025 2.88925 0.73025z"/>
        <path d="m126.079 110.585q0 0.34925-0.0635 0.92075h-13.6525q0.28575 2.2225 1.93675 3.58775 1.68275 1.3335 4.15925 1.3335 3.01625 0 4.85775-2.032l1.68275 1.9685q-1.143 1.3335-2.8575 2.032-1.68275 0.6985-3.77825 0.6985-2.667 0-4.73075-1.0795-2.06375-1.11125-3.20675-3.07975-1.11125-1.9685-1.11125-4.445 0-2.44475 1.0795-4.41325 1.11125-1.9685 3.01625-3.048 1.93675-1.11125 4.34975-1.11125t4.28625 1.11125q1.905 1.0795 2.95275 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.19075 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.12725-1.74625-3.46075-1.4605-1.36525-3.65125-1.36525z"/>
        <path d="m135.953 101.917q3.4925 0 5.334 1.7145 1.87325 1.7145 1.87325 5.11175v10.16h-2.88925v-2.2225q-0.762 1.17475-2.19075 1.80975-1.397 0.60325-3.33375 0.60325-2.82575 0-4.54025-1.36525-1.68275-1.36525-1.68275-3.58775t1.61925-3.556q1.61925-1.36525 5.1435-1.36525h4.826v-0.60325q0-1.9685-1.143-3.01625t-3.3655-1.04775q-1.49225 0-2.921 0.508-1.42875 0.47625-2.413 1.30175l-1.27-2.286q1.30175-1.04775 3.1115-1.5875 1.80975-0.5715 3.84175-0.5715zm-0.6985 14.8272q1.74625 0 3.01625-0.762 1.27-0.79375 1.8415-2.2225v-2.3495h-4.699q-3.8735 0-3.8735 2.6035 0 1.27 0.98425 2.00025t2.7305 0.73025z"/>
        <path d="m158.273 117.919q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.36525t-1.397-3.90525v-9.23925h-2.8575v-2.50825h2.8575v-3.683h3.048v3.683h4.826v2.50825h-4.826v9.11225q0 1.36525 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.38125-0.79375z"/>
        <path d="m177.704 119.094q-2.50825 0-4.5085-1.11125t-3.14325-3.048q-1.11125-1.9685-1.11125-4.445t1.11125-4.41325q1.143-1.9685 3.14325-3.048 2.00025-1.11125 4.5085-1.11125t4.47675 1.11125q2.00025 1.0795 3.1115 3.048 1.143 1.93675 1.143 4.41325t-1.143 4.445q-1.11125 1.93675-3.1115 3.048-1.9685 1.11125-4.47675 1.11125zm0-2.667q1.61925 0 2.88925-0.73025 1.30175-0.762 2.032-2.0955 0.73025-1.36525 0.73025-3.1115t-0.73025-3.07975q-0.73025-1.36525-2.032-2.0955-1.27-0.73025-2.88925-0.73025t-2.921 0.73025q-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.07975t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.30175 0.73025 2.921 0.73025z"/>
        <path d="m196.818 97.6626q-2.7305 0-2.7305 2.95275v1.4605h4.8895v2.50825h-4.826v14.3192h-3.048v-14.3192h-2.8575v-2.50825h2.8575v-1.49225q0-2.50825 1.4605-3.96875t4.09575-1.4605q1.016 0 1.905 0.254t1.524 0.73025l-0.92075 2.31775q-1.04775-0.79375-2.3495-0.79375z"/>
        <path d="m219.868 117.919q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.36525t-1.397-3.90525v-9.23925h-2.8575v-2.50825h2.8575v-3.683h3.048v3.683h4.826v2.50825h-4.826v9.11225q0 1.36525 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.38125-0.79375z"/>
        <path d="m232.537 101.917q3.20675 0 5.08 1.87325 1.905 1.8415 1.905 5.42925v9.68375h-3.048v-9.3345q0-2.44475-1.17475-3.683t-3.3655-1.23825q-2.4765 0-3.90525 1.4605-1.42875 1.42875-1.42875 4.1275v8.66775h-3.048v-23.5585h3.048v9.11225q0.9525-1.2065 2.4765-1.87325 1.55575-0.66675 3.46075-0.66675z"/>
        <path d="m260.476 110.585q0 0.34925-0.0635 0.92075h-13.6525q0.28575 2.2225 1.93675 3.58775 1.68275 1.3335 4.15925 1.3335 3.01625 0 4.85775-2.032l1.68275 1.9685q-1.143 1.3335-2.8575 2.032-1.68275 0.6985-3.77825 0.6985-2.667 0-4.73075-1.0795-2.06375-1.11125-3.20675-3.07975-1.11125-1.9685-1.11125-4.445 0-2.44475 1.0795-4.41325 1.11125-1.9685 3.01625-3.048 1.93675-1.11125 4.34975-1.11125t4.28625 1.11125q1.905 1.0795 2.95275 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.19075 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.12725-1.74625-3.46075-1.4605-1.36525-3.65125-1.36525z"/>
        <path d="m7.747 150.875q-2.06375 0-3.96875-0.53975-1.905-0.5715-2.9845-1.42875l1.27-2.413q1.11125 0.79375 2.69875 1.27t3.20675 0.47625q4.0005 0 4.0005-2.286 0-0.762-0.53975-1.2065t-1.36525-0.635q-0.79375-0.22225-2.286-0.47625-2.032-0.3175-3.33375-0.73025-1.27-0.41275-2.19075-1.397t-0.92075-2.76225q0-2.286 1.905-3.65125 1.905-1.397 5.11175-1.397 1.68275 0 3.3655 0.41275t2.76225 1.11125l-1.30175 2.413q-2.06375-1.3335-4.85775-1.3335-1.93675 0-2.95275 0.635t-1.016 1.68275q0 0.8255 0.5715 1.30175t1.397 0.6985q0.85725 0.22225 2.38125 0.508 2.032 0.34925 3.27025 0.762 1.27 0.381 2.159 1.3335t0.889 2.667q0 2.286-1.9685 3.65125-1.93675 1.3335-5.30225 1.3335z"/>
        <path d="m26.035 150.875q-2.50825 0-4.5085-1.11125t-3.14325-3.048q-1.11125-1.9685-1.11125-4.445t1.11125-4.41325q1.143-1.9685 3.14325-3.048 2.00025-1.11125 4.5085-1.11125t4.47675 1.11125q2.00025 1.0795 3.1115 3.048 1.143 1.93675 1.143 4.41325t-1.143 4.445q-1.11125 1.93675-3.1115 3.048-1.9685 1.11125-4.47675 1.11125zm0-2.667q1.61925 0 2.88925-0.73025 1.30175-0.762 2.032-2.0955 0.73025-1.36525 0.73025-3.1115t-0.73025-3.07975q-0.73025-1.36525-2.032-2.0955-1.27-0.73025-2.88925-0.73025t-2.921 0.73025q-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.07975t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.30175 0.73025 2.921 0.73025z"/>
        <path d="m46.3232 150.875q-2.57175 0-4.60375-1.11125-2.00025-1.11125-3.14325-3.048-1.143-1.9685-1.143-4.445t1.143-4.41325q1.143-1.9685 3.14325-3.048 2.032-1.11125 4.60375-1.11125 2.286 0 4.064 0.92075 1.80975 0.92075 2.794 2.667l-2.31775 1.49225q-0.79375-1.2065-1.9685-1.80975t-2.6035-0.60325q-1.651 0-2.9845 0.73025-1.30175 0.73025-2.06375 2.0955-0.73025 1.3335-0.73025 3.07975 0 1.778 0.73025 3.14325 0.762 1.3335 2.06375 2.06375 1.3335 0.73025 2.9845 0.73025 1.42875 0 2.6035-0.60325t1.9685-1.80975l2.31775 1.4605q-0.98425 1.74625-2.794 2.69875-1.778 0.92075-4.064 0.92075z"/>
        <path d="m57.1182 133.857h3.048v16.8275h-3.048zm1.524-3.2385q-0.889 0-1.49225-0.5715-0.5715-0.5715-0.5715-1.397t0.5715-1.397q0.60325-0.60325 1.49225-0.60325t1.4605 0.5715q0.60325 0.53975 0.60325 1.36525 0 0.85725-0.60325 1.4605-0.5715 0.5715-1.4605 0.5715z"/>
        <path d="m81.1846 142.366q0 0.34925-0.0635 0.92075h-13.6525q0.28575 2.2225 1.93675 3.58775 1.68275 1.3335 4.15925 1.3335 3.01625 0 4.85775-2.032l1.68275 1.9685q-1.143 1.3335-2.8575 2.032-1.68275 0.6985-3.77825 0.6985-2.667 0-4.73075-1.0795-2.06375-1.11125-3.20675-3.07975-1.11125-1.9685-1.11125-4.445 0-2.44475 1.0795-4.41325 1.11125-1.9685 3.01625-3.048 1.93675-1.11125 4.34975-1.11125t4.28625 1.11125q1.905 1.0795 2.95275 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.19075 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.12725-1.74625-3.46075-1.4605-1.36525-3.65125-1.36525z"/>
        <path d="m94.8689 149.701q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.36525t-1.397-3.90525v-9.23925h-2.8575v-2.50825h2.8575v-3.683h3.048v3.683h4.826v2.50825h-4.826v9.11225q0 1.36525 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.38125-0.79375z"/>
        <path d="m113.125 133.857-8.15975 18.5102c-0.71967 1.7145-1.56633 2.921-2.54 3.6195-0.9525 0.6985-2.10608 1.04775-3.46075 1.04775-0.8255 0-1.62983-0.13758-2.413-0.41275-0.762-0.254-1.397-0.635-1.905-1.143l1.30175-2.286c0.867833 0.8255 1.87325 1.23825 3.01625 1.23825 0.740835 0 1.35467-0.20108 1.8415-0.60325 0.508-0.381 0.96308-1.04775 1.36525-2.00025l0.53975-1.17475-7.4295-16.7958h3.175l5.87375 13.462 5.81025-13.462h2.97291z"/>
        <path d="m133.985 149.701q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.36525t-1.397-3.90525v-9.23925h-2.8575v-2.50825h2.8575v-3.683h3.048v3.683h4.826v2.50825h-4.826v9.11225q0 1.36525 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.38125-0.79375z"/>
        <path d="m144.303 150.875q-2.50825 0-4.5085-1.11125t-3.14325-3.048q-1.11125-1.9685-1.11125-4.445t1.11125-4.41325q1.143-1.9685 3.14325-3.048 2.00025-1.11125 4.5085-1.11125t4.47675 1.11125q2.00025 1.0795 3.1115 3.048 1.143 1.93675 1.143 4.41325t-1.143 4.445q-1.11125 1.93675-3.1115 3.048-1.9685 1.11125-4.47675 1.11125zm0-2.667q1.61925 0 2.88925-0.73025 1.30175-0.762 2.032-2.0955 0.73025-1.36525 0.73025-3.1115t-0.73025-3.07975q-0.73025-1.36525-2.032-2.0955-1.27-0.73025-2.88925-0.73025t-2.921 0.73025q-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.07975t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.30175 0.73025 2.921 0.73025z"/>
        <path d="m181.673 127.126v23.5585h-2.921v-2.667q-1.016 1.397-2.57175 2.12725t-3.429 0.73025q-2.44475 0-4.3815-1.0795t-3.048-3.01625q-1.0795-1.9685-1.0795-4.5085t1.0795-4.47675q1.11125-1.93675 3.048-3.01625t4.3815-1.0795q1.80975 0 3.33375 0.6985 1.524 0.66675 2.54 2.00025v-9.271zm-8.66775 21.082q1.5875 0 2.88925-0.73025 1.30175-0.762 2.032-2.0955 0.73025-1.36525 0.73025-3.1115t-0.73025-3.07975q-0.73025-1.36525-2.032-2.0955t-2.88925-0.73025q-1.61925 0-2.921 0.73025-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.07975t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.30175 0.73025 2.921 0.73025z"/>
        <path d="m202.66 142.366q0 0.34925-0.0635 0.92075h-13.6525q0.28575 2.2225 1.93675 3.58775 1.68275 1.3335 4.15925 1.3335 3.01625 0 4.85775-2.032l1.68275 1.9685q-1.143 1.3335-2.8575 2.032-1.68275 0.6985-3.77825 0.6985-2.667 0-4.73075-1.0795-2.06375-1.11125-3.20675-3.07975-1.11125-1.9685-1.11125-4.445 0-2.44475 1.0795-4.41325 1.11125-1.9685 3.01625-3.048 1.93675-1.11125 4.34975-1.11125t4.28625 1.11125q1.905 1.0795 2.95275 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.19075 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.12725-1.74625-3.46075-1.4605-1.36525-3.65125-1.36525z"/>
        <path d="m216.344 149.701q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.36525t-1.397-3.90525v-9.23925h-2.8575v-2.50825h2.8575v-3.683h3.048v3.683h4.826v2.50825h-4.826v9.11225q0 1.36525 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.38125-0.79375z"/>
        <path d="m234.664 142.366q0 0.34925-0.0635 0.92075h-13.6525q0.28575 2.2225 1.93675 3.58775 1.68275 1.3335 4.15925 1.3335 3.01625 0 4.85775-2.032l1.68275 1.9685q-1.143 1.3335-2.8575 2.032-1.68275 0.6985-3.77825 0.6985-2.667 0-4.73075-1.0795-2.06375-1.11125-3.20675-3.07975-1.11125-1.9685-1.11125-4.445 0-2.44475 1.0795-4.41325 1.11125-1.9685 3.01625-3.048 1.93675-1.11125 4.34975-1.11125t4.28625 1.11125q1.905 1.0795 2.95275 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.19075 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.12725-1.74625-3.46075-1.4605-1.36525-3.65125-1.36525z"/>
        <path d="m246.221 150.875q-2.57175 0-4.60375-1.11125-2.00025-1.11125-3.14325-3.048-1.143-1.9685-1.143-4.445t1.143-4.41325q1.143-1.9685 3.14325-3.048 2.032-1.11125 4.60375-1.11125 2.286 0 4.064 0.92075 1.80975 0.92075 2.794 2.667l-2.31775 1.49225q-0.79375-1.2065-1.9685-1.80975t-2.6035-0.60325q-1.651 0-2.9845 0.73025-1.30175 0.73025-2.06375 2.0955-0.73025 1.3335-0.73025 3.07975 0 1.778 0.73025 3.14325 0.762 1.3335 2.06375 2.06375 1.3335 0.73025 2.9845 0.73025 1.42875 0 2.6035-0.60325t1.9685-1.80975l2.31775 1.4605q-0.98425 1.74625-2.794 2.69875-1.778 0.92075-4.064 0.92075z"/>
        <path d="m266.699 149.701q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.36525t-1.397-3.90525v-9.23925h-2.8575v-2.50825h2.8575v-3.683h3.048v3.683h4.826v2.50825h-4.826v9.11225q0 1.36525 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.38125-0.79375z"/>
        <path d="m9.0805 161.226q-2.7305 0-2.7305 2.95275v1.4605h4.8895v2.50825h-4.826v14.3192h-3.048v-14.3192h-2.8575v-2.50825h2.8575v-1.49225q0-2.50825 1.4605-3.96875t4.09575-1.4605q1.016 0 1.905 0.254t1.524 0.73025l-0.92075 2.31775q-1.04775-0.79375-2.3495-0.79375z"/>
        <path d="m29.8132 165.639v16.8275h-2.88925v-2.54q-0.92075 1.30175-2.44475 2.032-1.49225 0.6985-3.27025 0.6985-3.3655 0-5.30225-1.8415-1.93675-1.87325-1.93675-5.49275v-9.68375h3.048v9.3345q0 2.44475 1.17475 3.71475 1.17475 1.23825 3.3655 1.23825 2.413 0 3.81-1.4605t1.397-4.1275v-8.6995z"/>
        <path d="m45.0849 181.482q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.36525t-1.397-3.90525v-9.23925h-2.8575v-2.50825h2.8575v-3.683h3.048v3.683h4.826v2.50825h-4.826v9.11225q0 1.36525 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.38125-0.79375z"/>
        <path d="m64.4524 165.639v16.8275h-2.88925v-2.54q-0.92075 1.30175-2.44475 2.032-1.49225 0.6985-3.27025 0.6985-3.3655 0-5.30225-1.8415-1.93675-1.87325-1.93675-5.49275v-9.68375h3.048v9.3345q0 2.44475 1.17475 3.71475 1.17475 1.23825 3.3655 1.23825 2.413 0 3.81-1.4605t1.397-4.1275v-8.6995z"/>
        <path d="m73.1836 168.465q0.79375-1.4605 2.3495-2.2225t3.77825-0.762v2.95275q-0.254-0.0318-0.6985-0.0318-2.4765 0-3.90525 1.49225-1.397 1.4605-1.397 4.191v8.382h-3.048v-16.8275h2.921z"/>
        <path d="m98.1073 174.148q0 0.34925-0.0635 0.92075h-13.6525q0.28575 2.2225 1.93675 3.58775 1.68275 1.3335 4.15925 1.3335 3.01625 0 4.85775-2.032l1.68275 1.9685q-1.143 1.3335-2.8575 2.032-1.68275 0.6985-3.77825 0.6985-2.667 0-4.73075-1.0795-2.06375-1.11125-3.20675-3.07975-1.11125-1.9685-1.11125-4.445 0-2.44475 1.0795-4.41325 1.11125-1.9685 3.01625-3.048 1.93675-1.11125 4.34975-1.11125t4.28625 1.11125q1.905 1.0795 2.95275 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.19075 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.12725-1.74625-3.46075-1.4605-1.36525-3.65125-1.36525z"/>
        <path d="m120.332 181.482q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.36525t-1.397-3.90525v-9.23925h-2.8575v-2.50825h2.8575v-3.683h3.048v3.683h4.826v2.50825h-4.826v9.11225q0 1.36525 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.38125-0.79375z"/>
        <path d="m126.936 168.465q0.79375-1.4605 2.3495-2.2225t3.77825-0.762v2.95275q-0.254-0.0318-0.6985-0.0318-2.4765 0-3.90525 1.49225-1.397 1.4605-1.397 4.191v8.382h-3.048v-16.8275h2.921z"/>
        <path d="m151.86 174.148q0 0.34925-0.0635 0.92075h-13.6525q0.28575 2.2225 1.93675 3.58775 1.68275 1.3335 4.15925 1.3335 3.01625 0 4.85775-2.032l1.68275 1.9685q-1.143 1.3335-2.8575 2.032-1.68275 0.6985-3.77825 0.6985-2.667 0-4.73075-1.0795-2.06375-1.11125-3.20675-3.07975-1.11125-1.9685-1.11125-4.445 0-2.44475 1.0795-4.41325 1.11125-1.9685 3.01625-3.048 1.93675-1.11125 4.34975-1.11125t4.28625 1.11125q1.905 1.0795 2.95275 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.19075 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.12725-1.74625-3.46075-1.4605-1.36525-3.65125-1.36525z"/>
        <path d="m165.068 165.48q3.20675 0 5.08 1.87325 1.905 1.8415 1.905 5.42925v9.68375h-3.048v-9.3345q0-2.44475-1.17475-3.683t-3.3655-1.23825q-2.4765 0-3.90525 1.4605-1.42875 1.42875-1.42875 4.1275v8.66775h-3.048v-16.8275h2.921v2.54q0.92075-1.30175 2.4765-2.00025 1.5875-0.6985 3.58775-0.6985z"/>
        <path d="m193.675 158.908v23.5585h-2.921v-2.667q-1.016 1.397-2.57175 2.12725t-3.429 0.73025q-2.44475 0-4.3815-1.0795t-3.048-3.01625q-1.0795-1.9685-1.0795-4.5085t1.0795-4.47675q1.11125-1.93675 3.048-3.01625t4.3815-1.0795q1.80975 0 3.33375 0.6985 1.524 0.66675 2.54 2.00025v-9.271zm-8.66775 21.082q1.5875 0 2.88925-0.73025 1.30175-0.762 2.032-2.0955 0.73025-1.36525 0.73025-3.1115t-0.73025-3.07975q-0.73025-1.36525-2.032-2.0955t-2.88925-0.73025q-1.61925 0-2.921 0.73025-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.07975t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.30175 0.73025 2.921 0.73025z"/>
        <path d="m204.279 182.657q-2.06375 0-3.96875-0.53975-1.905-0.5715-2.9845-1.42875l1.27-2.413q1.11125 0.79375 2.69875 1.27t3.20675 0.47625q4.0005 0 4.0005-2.286 0-0.762-0.53975-1.2065t-1.36525-0.635q-0.79375-0.22225-2.286-0.47625-2.032-0.3175-3.33375-0.73025-1.27-0.41275-2.19075-1.397t-0.92075-2.76225q0-2.286 1.905-3.65125 1.905-1.397 5.11175-1.397 1.68275 0 3.3655 0.41275t2.76225 1.11125l-1.30175 2.413q-2.06375-1.3335-4.85775-1.3335-1.93675 0-2.95275 0.635t-1.016 1.68275q0 0.8255 0.5715 1.30175t1.397 0.6985q0.85725 0.22225 2.38125 0.508 2.032 0.34925 3.27025 0.762 1.27 0.381 2.159 1.3335t0.889 2.667q0 2.286-1.9685 3.65125-1.93675 1.3335-5.30225 1.3335z"/>
        <path d="m216.09 182.657q-0.889 0-1.524-0.60325-0.635-0.635-0.635-1.5875 0-0.92075 0.635-1.524 0.635-0.635 1.524-0.635t1.49225 0.60325 0.60325 1.55575-0.635 1.5875q-0.60325 0.60325-1.4605 0.60325z"/>
      </g>
    </svg>

    <svg v-if="$i18n.locale === 'es'" width="1173" height="796.76" version="1.1" viewBox="0 0 310.35 210.81" xmlns="http://www.w3.org/2000/svg" class="distortion-svg">
      <text transform="scale(.26458)" fill="#000000" font-family="sans-serif" font-size="40px" style="line-height:1.25;shape-inside:url(#rect5557);white-space:pre" xml:space="preserve"/>
 <g stroke-width=".26458" aria-label="En Trends Lab escuchamos continuamente el latido de la sociedad en búsqueda de futuras tendencias.">
  <path d="m20.574 22.384v2.7622h-16.129v-22.225h15.685v2.7622h-12.51v6.8263h11.144v2.6988h-11.144v7.1755z"/>
  <path d="m34.258 8.1597q3.2068 0 5.08 1.8733 1.905 1.8415 1.905 5.4292v9.6838h-3.048v-9.3345q0-2.4448-1.1748-3.683t-3.3655-1.2382q-2.4765 0-3.9052 1.4605-1.4288 1.4288-1.4288 4.1275v8.6678h-3.048v-16.828h2.921v2.54q0.92075-1.3018 2.4765-2.0003 1.5875-0.6985 3.5878-0.6985z"/>
  <path d="m59.277 7.9057h-6.8262v-4.9848h19.907v4.9848h-6.7945v17.24h-6.2865z"/>
  <path d="m79.819 10.001q0.92075-1.1748 2.3812-1.7463 1.4922-0.60325 3.3972-0.60325v5.4293q-0.8255-0.09525-1.3652-0.09525-1.9368 0-3.048 1.0478-1.0795 1.0478-1.0795 3.2068v7.9058h-6.0325v-17.209h5.7468z"/>
  <path d="m105.5 16.574q0 0.0635-0.0953 1.4922h-12.509q0.381 1.3335 1.4605 2.0638 1.1112 0.6985 2.7622 0.6985 1.2382 0 2.1272-0.34925 0.92075-0.34925 1.8415-1.143l3.175 3.302q-2.5082 2.794-7.3342 2.794-3.0162 0-5.3022-1.143t-3.556-3.175q-1.2382-2.032-1.2382-4.6038 0-2.54 1.2065-4.5402 1.2382-2.032 3.3972-3.175t4.8578-1.143q2.5718 0 4.6672 1.0795 2.0955 1.0478 3.302 3.0798 1.2382 2.0002 1.2382 4.7625zm-9.1757-4.6355q-1.397 0-2.3495 0.79375-0.92075 0.79375-1.1748 2.159h7.0485q-0.254-1.3652-1.2065-2.159-0.92075-0.79375-2.3178-0.79375z"/>
  <path d="m119.51 7.6517q3.2068 0 5.1435 1.905 1.9685 1.905 1.9685 5.7468v9.8425h-6.0325v-8.8582q0-3.5242-2.8258-3.5242-1.5558 0-2.5082 1.016-0.92075 1.016-0.92075 3.048v8.3185h-6.0325v-17.209h5.7468v1.8732q1.016-1.0478 2.413-1.5875 1.397-0.5715 3.048-0.5715z"/>
  <path d="m148.94 1.5875v23.559h-5.7468v-1.7145q-1.6828 2.0002-5.0482 2.0002-2.3495 0-4.2862-1.0795-1.9368-1.1112-3.0798-3.1432-1.1112-2.032-1.1112-4.699t1.1112-4.6672q1.143-2.032 3.0798-3.1115t4.2862-1.0795q3.0798 0 4.7625 1.778v-7.8422zm-9.525 19.05q1.5558 0 2.5718-1.0795 1.016-1.1112 1.016-3.048 0-1.905-1.016-2.9845-0.98425-1.0795-2.5718-1.0795t-2.6035 1.0795-1.016 2.9845q0 1.9368 1.016 3.048 1.016 1.0795 2.6035 1.0795z"/>
  <path d="m159.07 25.432q-2.159 0-4.2545-0.47625-2.0955-0.508-3.3655-1.3018l1.8098-4.1275q1.1748 0.73025 2.794 1.1748 1.6192 0.41275 3.2068 0.41275 1.4605 0 2.0638-0.28575 0.635-0.3175 0.635-0.889t-0.762-0.79375q-0.73025-0.254-2.3495-0.47625-2.0638-0.254-3.5242-0.66675-1.4288-0.41275-2.4765-1.524t-1.0478-3.1115q0-1.651 0.98425-2.921 0.98425-1.3018 2.8575-2.032 1.905-0.762 4.5402-0.762 1.8732 0 3.7148 0.381t3.0798 1.0795l-1.8098 4.0958q-2.286-1.27-4.953-1.27-1.4288 0-2.0955 0.34925-0.66675 0.3175-0.66675 0.85725 0 0.60325 0.73025 0.85725 0.73025 0.22225 2.413 0.47625 2.1272 0.3175 3.5242 0.73025t2.413 1.524q1.0478 1.0795 1.0478 3.048 0 1.6192-0.98425 2.921-0.98425 1.27-2.921 2.0002-1.905 0.73025-4.6038 0.73025z"/>
  <path d="m179.7 2.921h6.2865v17.24h10.604v4.9848h-16.891z"/>
  <path d="m206.37 7.6517q4.2862 0 6.5405 1.9685 2.2542 1.9368 2.2542 5.969v9.5568h-5.6198v-2.2225q-1.3018 2.5082-5.08 2.5082-2.0002 0-3.4608-0.6985t-2.2225-1.8732q-0.73025-1.2065-0.73025-2.7305 0-2.4448 1.8732-3.7782 1.8732-1.3652 5.7785-1.3652h3.429q-0.15875-2.7305-3.6512-2.7305-1.2382 0-2.5082 0.41275-1.27 0.381-2.159 1.0795l-2.032-4.0958q1.4288-0.9525 3.4608-1.4605 2.0638-0.53975 4.1275-0.53975zm-0.22225 13.97q1.0478 0 1.8415-0.508t1.143-1.4922v-1.4922h-2.6035q-2.6352 0-2.6352 1.7462 0 0.79375 0.60325 1.27t1.651 0.47625z"/>
  <path d="m229.81 7.6517q2.3495 0 4.2545 1.0795 1.9368 1.0795 3.048 3.1115 1.143 2.0002 1.143 4.6672t-1.143 4.699q-1.1112 2.032-3.048 3.1432-1.905 1.0795-4.2545 1.0795-3.3972 0-5.08-2.0002v1.7145h-5.7468v-23.559h6.0325v7.8422q1.7462-1.778 4.7942-1.778zm-1.27 12.986q1.5875 0 2.6035-1.0795 1.016-1.1112 1.016-3.048 0-1.905-1.016-2.9845t-2.6035-1.0795-2.6035 1.0795-1.016 2.9845q0 1.9368 1.016 3.048 1.016 1.0795 2.6035 1.0795z"/>
  <path d="m19.208 47.519q0 0.34925-0.0635 0.92075h-13.653q0.28575 2.2225 1.9368 3.5878 1.6828 1.3335 4.1593 1.3335 3.0162 0 4.8578-2.032l1.6828 1.9685q-1.143 1.3335-2.8575 2.032-1.6828 0.6985-3.7782 0.6985-2.667 0-4.7308-1.0795-2.0638-1.1112-3.2068-3.0798-1.1112-1.9685-1.1112-4.445 0-2.4448 1.0795-4.4132 1.1112-1.9685 3.0162-3.048 1.9368-1.1112 4.3498-1.1112 2.413 0 4.2862 1.1112 1.905 1.0795 2.9528 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.1908 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.1272-1.7462-3.4608-1.4605-1.3652-3.6512-1.3652z"/>
  <path d="m28.257 56.028q-2.0638 0-3.9688-0.53975-1.905-0.5715-2.9845-1.4288l1.27-2.413q1.1112 0.79375 2.6988 1.27t3.2068 0.47625q4.0005 0 4.0005-2.286 0-0.762-0.53975-1.2065t-1.3652-0.635q-0.79375-0.22225-2.286-0.47625-2.032-0.3175-3.3338-0.73025-1.27-0.41275-2.1908-1.397t-0.92075-2.7622q0-2.286 1.905-3.6512 1.905-1.397 5.1118-1.397 1.6828 0 3.3655 0.41275t2.7622 1.1112l-1.3018 2.413q-2.0638-1.3335-4.8578-1.3335-1.9368 0-2.9528 0.635t-1.016 1.6828q0 0.8255 0.5715 1.3018t1.397 0.6985q0.85725 0.22225 2.3812 0.508 2.032 0.34925 3.2702 0.762 1.27 0.381 2.159 1.3335t0.889 2.667q0 2.286-1.9685 3.6512-1.9368 1.3335-5.3022 1.3335z"/>
  <path d="m46.672 56.028q-2.5718 0-4.6038-1.1112-2.0002-1.1112-3.1432-3.048-1.143-1.9685-1.143-4.445t1.143-4.4132q1.143-1.9685 3.1432-3.048 2.032-1.1112 4.6038-1.1112 2.286 0 4.064 0.92075 1.8098 0.92075 2.794 2.667l-2.3178 1.4922q-0.79375-1.2065-1.9685-1.8098t-2.6035-0.60325q-1.651 0-2.9845 0.73025-1.3018 0.73025-2.0638 2.0955-0.73025 1.3335-0.73025 3.0798 0 1.778 0.73025 3.1432 0.762 1.3335 2.0638 2.0638 1.3335 0.73025 2.9845 0.73025 1.4288 0 2.6035-0.60325t1.9685-1.8098l2.3178 1.4605q-0.98425 1.7462-2.794 2.6988-1.778 0.92075-4.064 0.92075z"/>
  <path d="m73.151 39.01v16.828h-2.8892v-2.54q-0.92075 1.3018-2.4448 2.032-1.4922 0.6985-3.2702 0.6985-3.3655 0-5.3022-1.8415-1.9368-1.8732-1.9368-5.4928v-9.6838h3.048v9.3345q0 2.4448 1.1748 3.7148 1.1748 1.2382 3.3655 1.2382 2.413 0 3.81-1.4605t1.397-4.1275v-8.6995z"/>
  <path d="m86.296 56.028q-2.5718 0-4.6038-1.1112-2.0002-1.1112-3.1432-3.048-1.143-1.9685-1.143-4.445t1.143-4.4132q1.143-1.9685 3.1432-3.048 2.032-1.1112 4.6038-1.1112 2.286 0 4.064 0.92075 1.8098 0.92075 2.794 2.667l-2.3178 1.4922q-0.79375-1.2065-1.9685-1.8098t-2.6035-0.60325q-1.651 0-2.9845 0.73025-1.3018 0.73025-2.0638 2.0955-0.73025 1.3335-0.73025 3.0798 0 1.778 0.73025 3.1432 0.762 1.3335 2.0638 2.0638 1.3335 0.73025 2.9845 0.73025 1.4288 0 2.6035-0.60325t1.9685-1.8098l2.3178 1.4605q-0.98425 1.7462-2.794 2.6988-1.778 0.92075-4.064 0.92075z"/>
  <path d="m105.85 38.851q3.2068 0 5.08 1.8732 1.905 1.8415 1.905 5.4292v9.6838h-3.048v-9.3345q0-2.4448-1.1748-3.683t-3.3655-1.2382q-2.4765 0-3.9052 1.4605-1.4288 1.4288-1.4288 4.1275v8.6678h-3.048v-23.558h3.048v9.1122q0.9525-1.2065 2.4765-1.8732 1.5558-0.66675 3.4608-0.66675z"/>
  <path d="m124.62 38.851q3.4925 0 5.334 1.7145 1.8732 1.7145 1.8732 5.1118v10.16h-2.8892v-2.2225q-0.762 1.1748-2.1908 1.8098-1.397 0.60325-3.3338 0.60325-2.8258 0-4.5402-1.3652-1.6828-1.3652-1.6828-3.5878t1.6192-3.556q1.6192-1.3652 5.1435-1.3652h4.826v-0.60325q0-1.9685-1.143-3.0162t-3.3655-1.0478q-1.4922 0-2.921 0.508-1.4288 0.47625-2.413 1.3018l-1.27-2.286q1.3018-1.0478 3.1115-1.5875 1.8098-0.5715 3.8418-0.5715zm-0.6985 14.827q1.7462 0 3.0162-0.762 1.27-0.79375 1.8415-2.2225v-2.3495h-4.699q-3.8735 0-3.8735 2.6035 0 1.27 0.98425 2.0002t2.7305 0.73025z"/>
  <path d="m158.53 38.851q3.175 0 5.0165 1.8415 1.8732 1.8415 1.8732 5.461v9.6838h-3.048v-9.3345q0-2.4448-1.143-3.683-1.1112-1.2382-3.2068-1.2382-2.3178 0-3.683 1.4605-1.3652 1.4288-1.3652 4.1275v8.6678h-3.048v-9.3345q0-2.4448-1.143-3.683-1.1112-1.2382-3.2068-1.2382-2.3178 0-3.683 1.4605-1.3652 1.4288-1.3652 4.1275v8.6678h-3.048v-16.828h2.921v2.5082q0.92075-1.3018 2.413-1.9685 1.4922-0.6985 3.3972-0.6985 1.9685 0 3.4925 0.79375t2.3495 2.3178q0.9525-1.4605 2.6352-2.286 1.7145-0.8255 3.8418-0.8255z"/>
  <path d="m178.34 56.028q-2.5082 0-4.5085-1.1112t-3.1432-3.048q-1.1112-1.9685-1.1112-4.445t1.1112-4.4132q1.143-1.9685 3.1432-3.048 2.0002-1.1112 4.5085-1.1112t4.4768 1.1112q2.0002 1.0795 3.1115 3.048 1.143 1.9368 1.143 4.4132t-1.143 4.445q-1.1112 1.9368-3.1115 3.048-1.9685 1.1112-4.4768 1.1112zm0-2.667q1.6192 0 2.8892-0.73025 1.3018-0.762 2.032-2.0955 0.73025-1.3652 0.73025-3.1115t-0.73025-3.0798q-0.73025-1.3652-2.032-2.0955-1.27-0.73025-2.8892-0.73025t-2.921 0.73025q-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.0798t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.3018 0.73025 2.921 0.73025z"/>
  <path d="m196.12 56.028q-2.0638 0-3.9688-0.53975-1.905-0.5715-2.9845-1.4288l1.27-2.413q1.1112 0.79375 2.6988 1.27t3.2068 0.47625q4.0005 0 4.0005-2.286 0-0.762-0.53975-1.2065t-1.3652-0.635q-0.79375-0.22225-2.286-0.47625-2.032-0.3175-3.3338-0.73025-1.27-0.41275-2.1908-1.397t-0.92075-2.7622q0-2.286 1.905-3.6512 1.905-1.397 5.1118-1.397 1.6828 0 3.3655 0.41275t2.7622 1.1112l-1.3018 2.413q-2.0638-1.3335-4.8578-1.3335-1.9368 0-2.9528 0.635t-1.016 1.6828q0 0.8255 0.5715 1.3018t1.397 0.6985q0.85725 0.22225 2.3812 0.508 2.032 0.34925 3.2702 0.762 1.27 0.381 2.159 1.3335t0.889 2.667q0 2.286-1.9685 3.6512-1.9368 1.3335-5.3022 1.3335z"/>
  <path d="m11.334 86.72q-2.5718 0-4.6038-1.1112-2.0002-1.1112-3.1432-3.048-1.143-1.9685-1.143-4.445t1.143-4.4132q1.143-1.9685 3.1432-3.048 2.032-1.1112 4.6038-1.1112 2.286 0 4.064 0.92075 1.8098 0.92075 2.794 2.667l-2.3178 1.4922q-0.79375-1.2065-1.9685-1.8098t-2.6035-0.60325q-1.651 0-2.9845 0.73025-1.3018 0.73025-2.0638 2.0955-0.73025 1.3335-0.73025 3.0798 0 1.778 0.73025 3.1432 0.762 1.3335 2.0638 2.0638 1.3335 0.73025 2.9845 0.73025 1.4288 0 2.6035-0.60325t1.9685-1.8098l2.3178 1.4605q-0.98425 1.7462-2.794 2.6988-1.778 0.92075-4.064 0.92075z"/>
  <path d="m29.082 86.72q-2.5082 0-4.5085-1.1112t-3.1432-3.048q-1.1112-1.9685-1.1112-4.445t1.1112-4.4132q1.143-1.9685 3.1432-3.048 2.0002-1.1112 4.5085-1.1112t4.4768 1.1112q2.0002 1.0795 3.1115 3.048 1.143 1.9368 1.143 4.4132t-1.143 4.445q-1.1112 1.9368-3.1115 3.048-1.9685 1.1112-4.4768 1.1112zm0-2.667q1.6192 0 2.8892-0.73025 1.3018-0.762 2.032-2.0955 0.73025-1.3652 0.73025-3.1115t-0.73025-3.0798q-0.73025-1.3652-2.032-2.0955-1.27-0.73025-2.8892-0.73025t-2.921 0.73025q-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.0798t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.3018 0.73025 2.921 0.73025z"/>
  <path d="m51.022 69.543q3.2068 0 5.08 1.8732 1.905 1.8415 1.905 5.4292v9.6838h-3.048v-9.3345q0-2.4448-1.1748-3.683t-3.3655-1.2382q-2.4765 0-3.9052 1.4605-1.4288 1.4288-1.4288 4.1275v8.6678h-3.048v-16.828h2.921v2.54q0.92075-1.3018 2.4765-2.0002 1.5875-0.6985 3.5878-0.6985z"/>
  <path d="m73.12 85.545q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.3652t-1.397-3.9052v-9.2392h-2.8575v-2.5082h2.8575v-3.683h3.048v3.683h4.826v2.5082h-4.826v9.1122q0 1.3652 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.3812-0.79375z"/>
  <path d="m76.803 69.702h3.048v16.828h-3.048zm1.524-3.2385q-0.889 0-1.4922-0.5715-0.5715-0.5715-0.5715-1.397t0.5715-1.397q0.60325-0.60325 1.4922-0.60325t1.4605 0.5715q0.60325 0.53975 0.60325 1.3652 0 0.85725-0.60325 1.4605-0.5715 0.5715-1.4605 0.5715z"/>
  <path d="m94.646 69.543q3.2068 0 5.08 1.8732 1.905 1.8415 1.905 5.4292v9.6838h-3.048v-9.3345q0-2.4448-1.1748-3.683t-3.3655-1.2382q-2.4765 0-3.9052 1.4605-1.4288 1.4288-1.4288 4.1275v8.6678h-3.048v-16.828h2.921v2.54q0.92075-1.3018 2.4765-2.0002 1.5875-0.6985 3.5878-0.6985z"/>
  <path d="m122.97 69.702v16.828h-2.8892v-2.54q-0.92075 1.3018-2.4448 2.032-1.4922 0.6985-3.2702 0.6985-3.3655 0-5.3022-1.8415-1.9368-1.8732-1.9368-5.4928v-9.6838h3.048v9.3345q0 2.4448 1.1748 3.7148 1.1748 1.2382 3.3655 1.2382 2.413 0 3.81-1.4605t1.397-4.1275v-8.6995z"/>
  <path d="m134.91 69.543q3.4925 0 5.334 1.7145 1.8732 1.7145 1.8732 5.1118v10.16h-2.8892v-2.2225q-0.762 1.1748-2.1908 1.8098-1.397 0.60325-3.3338 0.60325-2.8258 0-4.5402-1.3652-1.6828-1.3652-1.6828-3.5878t1.6192-3.556q1.6192-1.3652 5.1435-1.3652h4.826v-0.60325q0-1.9685-1.143-3.0162t-3.3655-1.0478q-1.4922 0-2.921 0.508-1.4288 0.47625-2.413 1.3018l-1.27-2.286q1.3018-1.0478 3.1115-1.5875 1.8098-0.5715 3.8418-0.5715zm-0.6985 14.827q1.7462 0 3.0162-0.762 1.27-0.79375 1.8415-2.2225v-2.3495h-4.699q-3.8735 0-3.8735 2.6035 0 1.27 0.98425 2.0002t2.7305 0.73025z"/>
  <path d="m168.81 69.543q3.175 0 5.0165 1.8415 1.8732 1.8415 1.8732 5.461v9.6838h-3.048v-9.3345q0-2.4448-1.143-3.683-1.1112-1.2382-3.2068-1.2382-2.3178 0-3.683 1.4605-1.3652 1.4288-1.3652 4.1275v8.6678h-3.048v-9.3345q0-2.4448-1.143-3.683-1.1112-1.2382-3.2068-1.2382-2.3178 0-3.683 1.4605-1.3652 1.4288-1.3652 4.1275v8.6678h-3.048v-16.828h2.921v2.5082q0.92075-1.3018 2.413-1.9685 1.4922-0.6985 3.3972-0.6985 1.9685 0 3.4925 0.79375t2.3495 2.3178q0.9525-1.4605 2.6352-2.286 1.7145-0.8255 3.8418-0.8255z"/>
  <path d="m196.63 78.211q0 0.34925-0.0635 0.92075h-13.652q0.28575 2.2225 1.9368 3.5878 1.6828 1.3335 4.1592 1.3335 3.0162 0 4.8578-2.032l1.6828 1.9685q-1.143 1.3335-2.8575 2.032-1.6828 0.6985-3.7782 0.6985-2.667 0-4.7308-1.0795-2.0638-1.1112-3.2068-3.0798-1.1112-1.9685-1.1112-4.445 0-2.4448 1.0795-4.4132 1.1112-1.9685 3.0162-3.048 1.9368-1.1112 4.3498-1.1112t4.2862 1.1112q1.905 1.0795 2.9528 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.1908 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.1272-1.7462-3.4608-1.4605-1.3652-3.6512-1.3652z"/>
  <path d="m209.83 69.543q3.2068 0 5.08 1.8732 1.905 1.8415 1.905 5.4292v9.6838h-3.048v-9.3345q0-2.4448-1.1748-3.683t-3.3655-1.2382q-2.4765 0-3.9052 1.4605-1.4288 1.4288-1.4288 4.1275v8.6678h-3.048v-16.828h2.921v2.54q0.92075-1.3018 2.4765-2.0002 1.5875-0.6985 3.5878-0.6985z"/>
  <path d="m231.93 85.545q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.3652t-1.397-3.9052v-9.2392h-2.8575v-2.5082h2.8575v-3.683h3.048v3.683h4.826v2.5082h-4.826v9.1122q0 1.3652 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.3812-0.79375z"/>
  <path d="m250.25 78.211q0 0.34925-0.0635 0.92075h-13.652q0.28575 2.2225 1.9368 3.5878 1.6828 1.3335 4.1592 1.3335 3.0162 0 4.8578-2.032l1.6828 1.9685q-1.143 1.3335-2.8575 2.032-1.6828 0.6985-3.7782 0.6985-2.667 0-4.7308-1.0795-2.0638-1.1112-3.2068-3.0798-1.1112-1.9685-1.1112-4.445 0-2.4448 1.0795-4.4132 1.1112-1.9685 3.0162-3.048 1.9368-1.1112 4.3498-1.1112t4.2862 1.1112q1.905 1.0795 2.9528 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.1908 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.1272-1.7462-3.4608-1.4605-1.3652-3.6512-1.3652z"/>
  <path d="m19.208 108.9q0 0.34925-0.0635 0.92075h-13.653q0.28575 2.2225 1.9368 3.5878 1.6828 1.3335 4.1593 1.3335 3.0162 0 4.8578-2.032l1.6828 1.9685q-1.143 1.3335-2.8575 2.032-1.6828 0.6985-3.7782 0.6985-2.667 0-4.7308-1.0795-2.0638-1.1112-3.2068-3.0798-1.1112-1.9685-1.1112-4.445 0-2.4448 1.0795-4.4132 1.1112-1.9685 3.0162-3.048 1.9368-1.1112 4.3498-1.1112 2.413 0 4.2862 1.1112 1.905 1.0795 2.9528 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.1908 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.1272-1.7462-3.4608-1.4605-1.3652-3.6512-1.3652z"/>
  <path d="m23.431 93.663h3.048v23.559h-3.048z"/>
  <path d="m40.83 93.663h3.048v23.559h-3.048z"/>
  <path d="m55.816 100.23q3.4925 0 5.334 1.7145 1.8732 1.7145 1.8732 5.1118v10.16h-2.8892v-2.2225q-0.762 1.1748-2.1908 1.8098-1.397 0.60325-3.3338 0.60325-2.8258 0-4.5402-1.3652-1.6828-1.3652-1.6828-3.5878t1.6192-3.556q1.6192-1.3652 5.1435-1.3652h4.826v-0.60325q0-1.9685-1.143-3.0162t-3.3655-1.0478q-1.4922 0-2.921 0.508-1.4288 0.47625-2.413 1.3018l-1.27-2.286q1.3018-1.0478 3.1115-1.5875 1.8098-0.5715 3.8418-0.5715zm-0.6985 14.827q1.7462 0 3.0162-0.762 1.27-0.79375 1.8415-2.2225v-2.3495h-4.699q-3.8735 0-3.8735 2.6035 0 1.27 0.98425 2.0002t2.7305 0.73025z"/>
  <path d="m78.136 116.24q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.3652t-1.397-3.9052v-9.2392h-2.8575v-2.5082h2.8575v-3.683h3.048v3.683h4.826v2.5082h-4.826v9.1122q0 1.3652 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.3812-0.79375z"/>
  <path d="m81.819 100.39h3.048v16.828h-3.048zm1.524-3.2385q-0.889 0-1.4922-0.5715-0.5715-0.5715-0.5715-1.397t0.5715-1.397q0.60325-0.60325 1.4922-0.60325t1.4605 0.5715q0.60325 0.53975 0.60325 1.3652 0 0.85725-0.60325 1.4605-0.5715 0.5715-1.4605 0.5715z"/>
  <path d="m106.55 93.663v23.559h-2.921v-2.667q-1.016 1.397-2.5718 2.1272-1.5558 0.73025-3.429 0.73025-2.4448 0-4.3815-1.0795t-3.048-3.0162q-1.0795-1.9685-1.0795-4.5085t1.0795-4.4768q1.1112-1.9368 3.048-3.0162t4.3815-1.0795q1.8098 0 3.3338 0.6985 1.524 0.66675 2.54 2.0002v-9.271zm-8.6678 21.082q1.5875 0 2.8893-0.73025 1.3018-0.762 2.032-2.0955 0.73025-1.3652 0.73025-3.1115t-0.73025-3.0798q-0.73025-1.3652-2.032-2.0955-1.3018-0.73025-2.8893-0.73025-1.6192 0-2.921 0.73025-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.0798t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.3018 0.73025 2.921 0.73025z"/>
  <path d="m119.54 117.41q-2.5082 0-4.5085-1.1112t-3.1432-3.048q-1.1112-1.9685-1.1112-4.445t1.1112-4.4132q1.143-1.9685 3.1432-3.048 2.0002-1.1112 4.5085-1.1112t4.4768 1.1112q2.0002 1.0795 3.1115 3.048 1.143 1.9368 1.143 4.4132t-1.143 4.445q-1.1112 1.9368-3.1115 3.048-1.9685 1.1112-4.4768 1.1112zm0-2.667q1.6192 0 2.8892-0.73025 1.3018-0.762 2.032-2.0955 0.73025-1.3652 0.73025-3.1115t-0.73025-3.0798q-0.73025-1.3652-2.032-2.0955-1.27-0.73025-2.8892-0.73025t-2.921 0.73025q-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.0798t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.3018 0.73025 2.921 0.73025z"/>
  <path d="m156.91 93.663v23.559h-2.921v-2.667q-1.016 1.397-2.5718 2.1272t-3.429 0.73025q-2.4448 0-4.3815-1.0795t-3.048-3.0162q-1.0795-1.9685-1.0795-4.5085t1.0795-4.4768q1.1112-1.9368 3.048-3.0162t4.3815-1.0795q1.8098 0 3.3338 0.6985 1.524 0.66675 2.54 2.0002v-9.271zm-8.6678 21.082q1.5875 0 2.8892-0.73025 1.3018-0.762 2.032-2.0955 0.73025-1.3652 0.73025-3.1115t-0.73025-3.0798q-0.73025-1.3652-2.032-2.0955t-2.8892-0.73025q-1.6192 0-2.921 0.73025-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.0798t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.3018 0.73025 2.921 0.73025z"/>
  <path d="m177.89 108.9q0 0.34925-0.0635 0.92075h-13.652q0.28575 2.2225 1.9368 3.5878 1.6828 1.3335 4.1592 1.3335 3.0162 0 4.8578-2.032l1.6828 1.9685q-1.143 1.3335-2.8575 2.032-1.6828 0.6985-3.7782 0.6985-2.667 0-4.7308-1.0795-2.0638-1.1112-3.2068-3.0798-1.1112-1.9685-1.1112-4.445 0-2.4448 1.0795-4.4132 1.1112-1.9685 3.0162-3.048 1.9368-1.1112 4.3498-1.1112t4.2862 1.1112q1.905 1.0795 2.9528 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.1908 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.1272-1.7462-3.4608-1.4605-1.3652-3.6512-1.3652z"/>
  <path d="m190.66 93.663h3.048v23.559h-3.048z"/>
  <path d="m205.64 100.23q3.4925 0 5.334 1.7145 1.8732 1.7145 1.8732 5.1118v10.16h-2.8892v-2.2225q-0.762 1.1748-2.1908 1.8098-1.397 0.60325-3.3338 0.60325-2.8258 0-4.5402-1.3652-1.6828-1.3652-1.6828-3.5878t1.6192-3.556q1.6192-1.3652 5.1435-1.3652h4.826v-0.60325q0-1.9685-1.143-3.0162t-3.3655-1.0478q-1.4922 0-2.921 0.508-1.4288 0.47625-2.413 1.3018l-1.27-2.286q1.3018-1.0478 3.1115-1.5875 1.8098-0.5715 3.8418-0.5715zm-0.6985 14.827q1.7462 0 3.0162-0.762 1.27-0.79375 1.8415-2.2225v-2.3495h-4.699q-3.8735 0-3.8735 2.6035 0 1.27 0.98425 2.0002t2.7305 0.73025z"/>
  <path d="m8.826 148.1q-2.0638 0-3.9688-0.53975-1.905-0.5715-2.9845-1.4288l1.27-2.413q1.1112 0.79375 2.6988 1.27t3.2068 0.47625q4.0005 0 4.0005-2.286 0-0.762-0.53975-1.2065t-1.3652-0.635q-0.79375-0.22225-2.286-0.47625-2.032-0.3175-3.3338-0.73025-1.27-0.41275-2.1908-1.397t-0.92075-2.7622q0-2.286 1.905-3.6512 1.905-1.397 5.1118-1.397 1.6828 0 3.3655 0.41275t2.7622 1.1112l-1.3018 2.413q-2.0638-1.3335-4.8578-1.3335-1.9368 0-2.9528 0.635t-1.016 1.6828q0 0.8255 0.5715 1.3018t1.397 0.6985q0.85725 0.22225 2.3812 0.508 2.032 0.34925 3.2703 0.762 1.27 0.381 2.159 1.3335t0.889 2.667q0 2.286-1.9685 3.6512-1.9368 1.3335-5.3023 1.3335z"/>
  <path d="m27.114 148.1q-2.5082 0-4.5085-1.1112t-3.1432-3.048q-1.1112-1.9685-1.1112-4.445t1.1112-4.4132q1.143-1.9685 3.1432-3.048 2.0002-1.1112 4.5085-1.1112t4.4768 1.1112q2.0002 1.0795 3.1115 3.048 1.143 1.9368 1.143 4.4132t-1.143 4.445q-1.1112 1.9368-3.1115 3.048-1.9685 1.1112-4.4768 1.1112zm0-2.667q1.6192 0 2.8892-0.73025 1.3018-0.762 2.032-2.0955 0.73025-1.3652 0.73025-3.1115t-0.73025-3.0798q-0.73025-1.3652-2.032-2.0955-1.27-0.73025-2.8892-0.73025t-2.921 0.73025q-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.0798t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.3018 0.73025 2.921 0.73025z"/>
  <path d="m47.402 148.1q-2.5718 0-4.6038-1.1112-2.0002-1.1112-3.1432-3.048-1.143-1.9685-1.143-4.445t1.143-4.4132q1.143-1.9685 3.1432-3.048 2.032-1.1112 4.6038-1.1112 2.286 0 4.064 0.92075 1.8098 0.92075 2.794 2.667l-2.3178 1.4922q-0.79375-1.2065-1.9685-1.8098t-2.6035-0.60325q-1.651 0-2.9845 0.73025-1.3018 0.73025-2.0638 2.0955-0.73025 1.3335-0.73025 3.0798 0 1.778 0.73025 3.1432 0.762 1.3335 2.0638 2.0638 1.3335 0.73025 2.9845 0.73025 1.4288 0 2.6035-0.60325t1.9685-1.8098l2.3178 1.4605q-0.98425 1.7462-2.794 2.6988-1.778 0.92075-4.064 0.92075z"/>
  <path d="m58.197 131.09h3.048v16.828h-3.048zm1.524-3.2385q-0.889 0-1.4922-0.5715-0.5715-0.5715-0.5715-1.397t0.5715-1.397q0.60325-0.60325 1.4922-0.60325t1.4605 0.5715q0.60325 0.53975 0.60325 1.3652 0 0.85725-0.60325 1.4605-0.5715 0.5715-1.4605 0.5715z"/>
  <path d="m82.264 139.59q0 0.34925-0.0635 0.92075h-13.652q0.28575 2.2225 1.9368 3.5878 1.6828 1.3335 4.1592 1.3335 3.0162 0 4.8578-2.032l1.6828 1.9685q-1.143 1.3335-2.8575 2.032-1.6828 0.6985-3.7782 0.6985-2.667 0-4.7308-1.0795-2.0638-1.1112-3.2068-3.0798-1.1112-1.9685-1.1112-4.445 0-2.4448 1.0795-4.4132 1.1112-1.9685 3.0162-3.048 1.9368-1.1112 4.3498-1.1112t4.2862 1.1112q1.905 1.0795 2.9528 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.1908 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.1272-1.7462-3.4608-1.4605-1.3652-3.6512-1.3652z"/>
  <path d="m102.36 124.35v23.558h-2.921v-2.667q-1.016 1.397-2.5718 2.1272t-3.429 0.73025q-2.4448 0-4.3815-1.0795t-3.048-3.0162q-1.0795-1.9685-1.0795-4.5085t1.0795-4.4768q1.1112-1.9368 3.048-3.0162t4.3815-1.0795q1.8098 0 3.3338 0.6985 1.524 0.66675 2.54 2.0002v-9.271zm-8.6678 21.082q1.5875 0 2.8892-0.73025 1.3018-0.762 2.032-2.0955 0.73025-1.3652 0.73025-3.1115t-0.73025-3.0798q-0.73025-1.3652-2.032-2.0955t-2.8892-0.73025q-1.6192 0-2.921 0.73025-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.0798t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.3018 0.73025 2.921 0.73025z"/>
  <path d="m114.27 130.93q3.4925 0 5.334 1.7145 1.8732 1.7145 1.8732 5.1118v10.16h-2.8892v-2.2225q-0.762 1.1748-2.1908 1.8098-1.397 0.60325-3.3338 0.60325-2.8258 0-4.5402-1.3652-1.6828-1.3652-1.6828-3.5878t1.6192-3.556q1.6192-1.3652 5.1435-1.3652h4.826v-0.60325q0-1.9685-1.143-3.0162t-3.3655-1.0478q-1.4922 0-2.921 0.508-1.4288 0.47625-2.413 1.3018l-1.27-2.286q1.3018-1.0478 3.1115-1.5875 1.8098-0.5715 3.8418-0.5715zm-0.6985 14.827q1.7462 0 3.0162-0.762 1.27-0.79375 1.8415-2.2225v-2.3495h-4.699q-3.8735 0-3.8735 2.6035 0 1.27 0.98425 2.0002t2.7305 0.73025z"/>
  <path d="m143.1 124.35v23.558h-2.921v-2.667q-1.016 1.397-2.5718 2.1272t-3.429 0.73025q-2.4448 0-4.3815-1.0795t-3.048-3.0162q-1.0795-1.9685-1.0795-4.5085t1.0795-4.4768q1.1112-1.9368 3.048-3.0162t4.3815-1.0795q1.8098 0 3.3338 0.6985 1.524 0.66675 2.54 2.0002v-9.271zm-8.6678 21.082q1.5875 0 2.8892-0.73025 1.3018-0.762 2.032-2.0955 0.73025-1.3652 0.73025-3.1115t-0.73025-3.0798q-0.73025-1.3652-2.032-2.0955t-2.8892-0.73025q-1.6192 0-2.921 0.73025-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.0798t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.3018 0.73025 2.921 0.73025z"/>
  <path d="m172.62 139.59q0 0.34925-0.0635 0.92075h-13.652q0.28575 2.2225 1.9368 3.5878 1.6828 1.3335 4.1592 1.3335 3.0162 0 4.8578-2.032l1.6828 1.9685q-1.143 1.3335-2.8575 2.032-1.6828 0.6985-3.7782 0.6985-2.667 0-4.7308-1.0795-2.0638-1.1112-3.2068-3.0798-1.1112-1.9685-1.1112-4.445 0-2.4448 1.0795-4.4132 1.1112-1.9685 3.0162-3.048 1.9368-1.1112 4.3498-1.1112t4.2862 1.1112q1.905 1.0795 2.9528 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.1908 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.1272-1.7462-3.4608-1.4605-1.3652-3.6512-1.3652z"/>
  <path d="m185.83 130.93q3.2068 0 5.08 1.8732 1.905 1.8415 1.905 5.4292v9.6838h-3.048v-9.3345q0-2.4448-1.1748-3.683t-3.3655-1.2382q-2.4765 0-3.9052 1.4605-1.4288 1.4288-1.4288 4.1275v8.6678h-3.048v-16.828h2.921v2.54q0.92075-1.3018 2.4765-2.0002 1.5875-0.6985 3.5878-0.6985z"/>
  <path d="m12.922 161.62q2.4448 0 4.3815 1.0795t3.0162 3.0162q1.1112 1.9368 1.1112 4.4768t-1.1112 4.5085q-1.0795 1.9368-3.0162 3.0162t-4.3815 1.0795q-1.8732 0-3.429-0.73025-1.5558-0.73025-2.5718-2.1272v2.667h-2.921v-23.558h3.048v9.271q1.016-1.3335 2.54-2.0002 1.524-0.6985 3.3338-0.6985zm-0.254 14.51q1.6192 0 2.8892-0.73025 1.3018-0.762 2.032-2.0955 0.762-1.3652 0.762-3.1115t-0.762-3.0798q-0.73025-1.3652-2.032-2.0955-1.27-0.73025-2.8892-0.73025-1.5875 0-2.8893 0.73025-1.3018 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.0798t0.73025 3.1115q0.73025 1.3335 2.032 2.0955 1.3018 0.73025 2.8893 0.73025z"/>
  <path d="m41.338 161.78v16.828h-2.8892v-2.54q-0.92075 1.3018-2.4448 2.032-1.4922 0.6985-3.2702 0.6985-3.3655 0-5.3022-1.8415-1.9368-1.8732-1.9368-5.4928v-9.6838h3.048v9.3345q0 2.4448 1.1748 3.7148 1.1748 1.2382 3.3655 1.2382 2.413 0 3.81-1.4605t1.397-4.1275v-8.6995zm-5.969-6.5722h3.8735l-5.334 4.1275h-2.8575z"/>
  <path d="m51.974 178.8q-2.0638 0-3.9688-0.53975-1.905-0.5715-2.9845-1.4288l1.27-2.413q1.1112 0.79375 2.6988 1.27t3.2068 0.47625q4.0005 0 4.0005-2.286 0-0.762-0.53975-1.2065t-1.3652-0.635q-0.79375-0.22225-2.286-0.47625-2.032-0.3175-3.3338-0.73025-1.27-0.41275-2.1908-1.397t-0.92075-2.7622q0-2.286 1.905-3.6512 1.905-1.397 5.1118-1.397 1.6828 0 3.3655 0.41275t2.7622 1.1112l-1.3018 2.413q-2.0638-1.3335-4.8578-1.3335-1.9368 0-2.9528 0.635t-1.016 1.6828q0 0.8255 0.5715 1.3018t1.397 0.6985q0.85725 0.22225 2.3812 0.508 2.032 0.34925 3.2702 0.762 1.27 0.381 2.159 1.3335t0.889 2.667q0 2.286-1.9685 3.6512-1.9368 1.3335-5.3022 1.3335z"/>
  <path d="m78.93 161.78v22.987h-3.048v-8.6995q-1.0478 1.3335-2.5718 2.032-1.4922 0.6985-3.302 0.6985-2.4448 0-4.3815-1.0795t-3.048-3.0162q-1.0795-1.9685-1.0795-4.5085t1.0795-4.4768q1.1112-1.9368 3.048-3.0162t4.3815-1.0795q1.8732 0 3.429 0.73025 1.5558 0.6985 2.5718 2.0955v-2.667zm-8.6678 14.351q1.5875 0 2.8892-0.73025 1.3018-0.762 2.032-2.0955 0.73025-1.3652 0.73025-3.1115t-0.762-3.0798q-0.73025-1.3335-2.032-2.0638-1.27-0.762-2.8575-0.762-1.6192 0-2.921 0.73025-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.0798t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.3018 0.73025 2.921 0.73025z"/>
  <path d="m100.39 161.78v16.828h-2.8892v-2.54q-0.92075 1.3018-2.4448 2.032-1.4922 0.6985-3.2702 0.6985-3.3655 0-5.3022-1.8415-1.9368-1.8732-1.9368-5.4928v-9.6838h3.048v9.3345q0 2.4448 1.1748 3.7148 1.1748 1.2382 3.3655 1.2382 2.413 0 3.81-1.4605t1.397-4.1275v-8.6995z"/>
  <path d="m121.41 170.29q0 0.34925-0.0635 0.92075h-13.652q0.28575 2.2225 1.9368 3.5878 1.6828 1.3335 4.1592 1.3335 3.0162 0 4.8578-2.032l1.6828 1.9685q-1.143 1.3335-2.8575 2.032-1.6828 0.6985-3.7782 0.6985-2.667 0-4.7308-1.0795-2.0638-1.1112-3.2068-3.0798-1.1112-1.9685-1.1112-4.445 0-2.4448 1.0795-4.4132 1.1112-1.9685 3.0162-3.048 1.9368-1.1112 4.3498-1.1112t4.2862 1.1112q1.905 1.0795 2.9528 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.1908 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.1272-1.7462-3.4608-1.4605-1.3652-3.6512-1.3652z"/>
  <path d="m141.51 155.05v23.558h-2.921v-2.667q-1.016 1.397-2.5718 2.1272t-3.429 0.73025q-2.4448 0-4.3815-1.0795t-3.048-3.0162q-1.0795-1.9685-1.0795-4.5085t1.0795-4.4768q1.1112-1.9368 3.048-3.0162t4.3815-1.0795q1.8098 0 3.3338 0.6985 1.524 0.66675 2.54 2.0002v-9.271zm-8.6678 21.082q1.5875 0 2.8892-0.73025 1.3018-0.762 2.032-2.0955 0.73025-1.3652 0.73025-3.1115t-0.73025-3.0798q-0.73025-1.3652-2.032-2.0955t-2.8892-0.73025q-1.6192 0-2.921 0.73025-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.0798t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.3018 0.73025 2.921 0.73025z"/>
  <path d="m153.42 161.62q3.4925 0 5.334 1.7145 1.8732 1.7145 1.8732 5.1118v10.16h-2.8892v-2.2225q-0.762 1.1748-2.1908 1.8098-1.397 0.60325-3.3338 0.60325-2.8258 0-4.5402-1.3652-1.6828-1.3652-1.6828-3.5878t1.6192-3.556q1.6192-1.3652 5.1435-1.3652h4.826v-0.60325q0-1.9685-1.143-3.0162t-3.3655-1.0478q-1.4922 0-2.921 0.508-1.4288 0.47625-2.413 1.3018l-1.27-2.286q1.3018-1.0478 3.1115-1.5875 1.8098-0.5715 3.8418-0.5715zm-0.6985 14.827q1.7462 0 3.0162-0.762 1.27-0.79375 1.8415-2.2225v-2.3495h-4.699q-3.8735 0-3.8735 2.6035 0 1.27 0.98425 2.0002t2.7305 0.73025z"/>
  <path d="m190.69 155.05v23.558h-2.921v-2.667q-1.016 1.397-2.5718 2.1272t-3.429 0.73025q-2.4448 0-4.3815-1.0795t-3.048-3.0162q-1.0795-1.9685-1.0795-4.5085t1.0795-4.4768q1.1112-1.9368 3.048-3.0162t4.3815-1.0795q1.8098 0 3.3338 0.6985 1.524 0.66675 2.54 2.0002v-9.271zm-8.6678 21.082q1.5875 0 2.8892-0.73025 1.3018-0.762 2.032-2.0955 0.73025-1.3652 0.73025-3.1115t-0.73025-3.0798q-0.73025-1.3652-2.032-2.0955t-2.8892-0.73025q-1.6192 0-2.921 0.73025-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.0798t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.3018 0.73025 2.921 0.73025z"/>
  <path d="m211.68 170.29q0 0.34925-0.0635 0.92075h-13.652q0.28575 2.2225 1.9368 3.5878 1.6828 1.3335 4.1592 1.3335 3.0162 0 4.8578-2.032l1.6828 1.9685q-1.143 1.3335-2.8575 2.032-1.6828 0.6985-3.7782 0.6985-2.667 0-4.7308-1.0795-2.0638-1.1112-3.2068-3.0798-1.1112-1.9685-1.1112-4.445 0-2.4448 1.0795-4.4132 1.1112-1.9685 3.0162-3.048 1.9368-1.1112 4.3498-1.1112t4.2862 1.1112q1.905 1.0795 2.9528 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.1908 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.1272-1.7462-3.4608-1.4605-1.3652-3.6512-1.3652z"/>
  <path d="m10.16 188.06q-2.7305 0-2.7305 2.9528v1.4605h4.8895v2.5082h-4.826v14.319h-3.048v-14.319h-2.8575v-2.5082h2.8575v-1.4922q0-2.5082 1.4605-3.9688t4.0958-1.4605q1.016 0 1.905 0.254t1.524 0.73025l-0.92075 2.3178q-1.0478-0.79375-2.3495-0.79375z"/>
  <path d="m30.892 192.47v16.828h-2.8892v-2.54q-0.92075 1.3018-2.4448 2.032-1.4922 0.6985-3.2702 0.6985-3.3655 0-5.3022-1.8415-1.9368-1.8732-1.9368-5.4928v-9.6838h3.048v9.3345q0 2.4448 1.1748 3.7148 1.1748 1.2382 3.3655 1.2382 2.413 0 3.81-1.4605t1.397-4.1275v-8.6995z"/>
  <path d="m46.164 208.31q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.3652t-1.397-3.9052v-9.2392h-2.8575v-2.5082h2.8575v-3.683h3.048v3.683h4.826v2.5082h-4.826v9.1122q0 1.3652 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.3812-0.79375z"/>
  <path d="m65.531 192.47v16.828h-2.8892v-2.54q-0.92075 1.3018-2.4448 2.032-1.4922 0.6985-3.2702 0.6985-3.3655 0-5.3022-1.8415-1.9368-1.8732-1.9368-5.4928v-9.6838h3.048v9.3345q0 2.4448 1.1748 3.7148 1.1748 1.2382 3.3655 1.2382 2.413 0 3.81-1.4605t1.397-4.1275v-8.6995z"/>
  <path d="m74.263 195.29q0.79375-1.4605 2.3495-2.2225t3.7782-0.762v2.9528q-0.254-0.0317-0.6985-0.0317-2.4765 0-3.9052 1.4922-1.397 1.4605-1.397 4.191v8.382h-3.048v-16.828h2.921z"/>
  <path d="m90.233 192.31q3.4925 0 5.334 1.7145 1.8732 1.7145 1.8732 5.1118v10.16h-2.8892v-2.2225q-0.762 1.1748-2.1908 1.8098-1.397 0.60325-3.3338 0.60325-2.8258 0-4.5402-1.3652-1.6828-1.3652-1.6828-3.5878t1.6192-3.556q1.6192-1.3652 5.1435-1.3652h4.826v-0.60325q0-1.9685-1.143-3.0162t-3.3655-1.0478q-1.4922 0-2.921 0.508-1.4288 0.47625-2.413 1.3018l-1.27-2.286q1.3018-1.0478 3.1115-1.5875 1.8098-0.5715 3.8418-0.5715zm-0.6985 14.827q1.7462 0 3.0162-0.762 1.27-0.79375 1.8415-2.2225v-2.3495h-4.699q-3.8735 0-3.8735 2.6035 0 1.27 0.98425 2.0002t2.7305 0.73025z"/>
  <path d="m107.92 209.49q-2.0638 0-3.9688-0.53975-1.905-0.5715-2.9845-1.4288l1.27-2.413q1.1112 0.79375 2.6988 1.27t3.2068 0.47625q4.0005 0 4.0005-2.286 0-0.762-0.53975-1.2065t-1.3652-0.635q-0.79375-0.22225-2.286-0.47625-2.032-0.3175-3.3338-0.73025-1.27-0.41275-2.1908-1.397t-0.92075-2.7622q0-2.286 1.905-3.6512 1.905-1.397 5.1118-1.397 1.6828 0 3.3655 0.41275t2.7622 1.1112l-1.3018 2.413q-2.0638-1.3335-4.8578-1.3335-1.9368 0-2.9528 0.635t-1.016 1.6828q0 0.8255 0.5715 1.3018t1.397 0.6985q0.85725 0.22225 2.3812 0.508 2.032 0.34925 3.2702 0.762 1.27 0.381 2.159 1.3335t0.889 2.667q0 2.286-1.9685 3.6512-1.9368 1.3335-5.3022 1.3335z"/>
  <path d="m137 208.31q-0.66675 0.5715-1.651 0.889-0.98425 0.28575-2.032 0.28575-2.54 0-3.937-1.3652t-1.397-3.9052v-9.2392h-2.8575v-2.5082h2.8575v-3.683h3.048v3.683h4.826v2.5082h-4.826v9.1122q0 1.3652 0.66675 2.0955 0.6985 0.73025 1.9685 0.73025 1.397 0 2.3812-0.79375z"/>
  <path d="m155.32 200.98q0 0.34925-0.0635 0.92075h-13.652q0.28575 2.2225 1.9368 3.5878 1.6828 1.3335 4.1592 1.3335 3.0162 0 4.8578-2.032l1.6828 1.9685q-1.143 1.3335-2.8575 2.032-1.6828 0.6985-3.7782 0.6985-2.667 0-4.7308-1.0795-2.0638-1.1112-3.2068-3.0798-1.1112-1.9685-1.1112-4.445 0-2.4448 1.0795-4.4132 1.1112-1.9685 3.0162-3.048 1.9368-1.1112 4.3498-1.1112t4.2862 1.1112q1.905 1.0795 2.9528 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.1908 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.1272-1.7462-3.4608-1.4605-1.3652-3.6512-1.3652z"/>
  <path d="m168.53 192.31q3.2068 0 5.08 1.8732 1.905 1.8415 1.905 5.4292v9.6838h-3.048v-9.3345q0-2.4448-1.1748-3.683t-3.3655-1.2382q-2.4765 0-3.9052 1.4605-1.4288 1.4288-1.4288 4.1275v8.6678h-3.048v-16.828h2.921v2.54q0.92075-1.3018 2.4765-2.0002 1.5875-0.6985 3.5878-0.6985z"/>
  <path d="m197.13 185.74v23.558h-2.921v-2.667q-1.016 1.397-2.5718 2.1272t-3.429 0.73025q-2.4448 0-4.3815-1.0795t-3.048-3.0162q-1.0795-1.9685-1.0795-4.5085t1.0795-4.4768q1.1112-1.9368 3.048-3.0162t4.3815-1.0795q1.8098 0 3.3338 0.6985 1.524 0.66675 2.54 2.0002v-9.271zm-8.6678 21.082q1.5875 0 2.8892-0.73025 1.3018-0.762 2.032-2.0955 0.73025-1.3652 0.73025-3.1115t-0.73025-3.0798q-0.73025-1.3652-2.032-2.0955t-2.8892-0.73025q-1.6192 0-2.921 0.73025-1.27 0.73025-2.032 2.0955-0.73025 1.3335-0.73025 3.0798t0.73025 3.1115q0.762 1.3335 2.032 2.0955 1.3018 0.73025 2.921 0.73025z"/>
  <path d="m218.12 200.98q0 0.34925-0.0635 0.92075h-13.652q0.28575 2.2225 1.9368 3.5878 1.6828 1.3335 4.1592 1.3335 3.0162 0 4.8578-2.032l1.6828 1.9685q-1.143 1.3335-2.8575 2.032-1.6828 0.6985-3.7782 0.6985-2.667 0-4.7308-1.0795-2.0638-1.1112-3.2068-3.0798-1.1112-1.9685-1.1112-4.445 0-2.4448 1.0795-4.4132 1.1112-1.9685 3.0162-3.048 1.9368-1.1112 4.3498-1.1112t4.2862 1.1112q1.905 1.0795 2.9528 3.048 1.0795 1.9685 1.0795 4.5085zm-8.3185-6.096q-2.1908 0-3.683 1.3335-1.4605 1.3335-1.7145 3.4925h10.795q-0.254-2.1272-1.7462-3.4608-1.4605-1.3652-3.6512-1.3652z"/>
  <path d="m231.33 192.31q3.2068 0 5.08 1.8732 1.905 1.8415 1.905 5.4292v9.6838h-3.048v-9.3345q0-2.4448-1.1748-3.683t-3.3655-1.2382q-2.4765 0-3.9052 1.4605-1.4288 1.4288-1.4288 4.1275v8.6678h-3.048v-16.828h2.921v2.54q0.92075-1.3018 2.4765-2.0002 1.5875-0.6985 3.5878-0.6985z"/>
  <path d="m251.4 209.49q-2.5718 0-4.6038-1.1112-2.0002-1.1112-3.1432-3.048-1.143-1.9685-1.143-4.445t1.143-4.4132q1.143-1.9685 3.1432-3.048 2.032-1.1112 4.6038-1.1112 2.286 0 4.064 0.92075 1.8098 0.92075 2.794 2.667l-2.3178 1.4922q-0.79375-1.2065-1.9685-1.8098t-2.6035-0.60325q-1.651 0-2.9845 0.73025-1.3018 0.73025-2.0638 2.0955-0.73025 1.3335-0.73025 3.0798 0 1.778 0.73025 3.1432 0.762 1.3335 2.0638 2.0638 1.3335 0.73025 2.9845 0.73025 1.4288 0 2.6035-0.60325t1.9685-1.8098l2.3178 1.4605q-0.98425 1.7462-2.794 2.6988-1.778 0.92075-4.064 0.92075z"/>
  <path d="m262.19 192.47h3.048v16.828h-3.048zm1.524-3.2385q-0.889 0-1.4922-0.5715-0.5715-0.5715-0.5715-1.397t0.5715-1.397q0.60325-0.60325 1.4922-0.60325t1.4605 0.5715q0.60325 0.53975 0.60325 1.3652 0 0.85725-0.60325 1.4605-0.5715 0.5715-1.4605 0.5715z"/>
  <path d="m277.18 192.31q3.4925 0 5.334 1.7145 1.8732 1.7145 1.8732 5.1118v10.16h-2.8892v-2.2225q-0.762 1.1748-2.1908 1.8098-1.397 0.60325-3.3338 0.60325-2.8258 0-4.5402-1.3652-1.6828-1.3652-1.6828-3.5878t1.6192-3.556q1.6192-1.3652 5.1435-1.3652h4.826v-0.60325q0-1.9685-1.143-3.0162t-3.3655-1.0478q-1.4922 0-2.921 0.508-1.4288 0.47625-2.413 1.3018l-1.27-2.286q1.3018-1.0478 3.1115-1.5875 1.8098-0.5715 3.8418-0.5715zm-0.6985 14.827q1.7462 0 3.0162-0.762 1.27-0.79375 1.8415-2.2225v-2.3495h-4.699q-3.8735 0-3.8735 2.6035 0 1.27 0.98425 2.0002t2.7305 0.73025z"/>
  <path d="m294.86 209.49q-2.0638 0-3.9688-0.53975-1.905-0.5715-2.9845-1.4288l1.27-2.413q1.1112 0.79375 2.6988 1.27t3.2068 0.47625q4.0005 0 4.0005-2.286 0-0.762-0.53975-1.2065t-1.3652-0.635q-0.79375-0.22225-2.286-0.47625-2.032-0.3175-3.3338-0.73025-1.27-0.41275-2.1908-1.397t-0.92075-2.7622q0-2.286 1.905-3.6512 1.905-1.397 5.1118-1.397 1.6828 0 3.3655 0.41275t2.7622 1.1112l-1.3018 2.413q-2.0638-1.3335-4.8578-1.3335-1.9368 0-2.9528 0.635t-1.016 1.6828q0 0.8255 0.5715 1.3018t1.397 0.6985q0.85725 0.22225 2.3812 0.508 2.032 0.34925 3.2702 0.762 1.27 0.381 2.159 1.3335t0.889 2.667q0 2.286-1.9685 3.6512-1.9368 1.3335-5.3022 1.3335z"/>
  <path d="m306.67 209.49q-0.889 0-1.524-0.60325-0.635-0.635-0.635-1.5875 0-0.92075 0.635-1.524 0.635-0.635 1.524-0.635t1.4922 0.60325 0.60325 1.5558-0.635 1.5875q-0.60325 0.60325-1.4605 0.60325z"/>
 </g>
    </svg>
  </div>
</template>

<script>
import {initDistortionAnimation} from '../assets/js/warp-animations'

export default {
  name: 'AtTrendsLabDistortionAnimation',
  mounted() {
    this.disposeAnimation = initDistortionAnimation(this.$refs.container.querySelector('svg'), 10)
  },
  updated() {
    this.disposeAnimation && this.disposeAnimation()
    this.disposeAnimation = initDistortionAnimation(this.$refs.container.querySelector('svg'), 10)
  },
  unmounted() {
    this.disposeAnimation()
  }
}
</script>

<style scoped>
.distortion-svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>