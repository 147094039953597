<template>
  <Hero />

  <!-- Fixed max-width wrapper -->
  <div class="delimiter">
    <!-- At Trends Lab... -->
    <div
      class="
      xl:pt-80 xl:pb-52 xl:px-44
      lg:pt-56 lg:pb-52 lg:px-32
      md:pt-44 md:pb-28 md:px-24
      pt-44 pb-28 px-8"
    >
      <at-trends-lab-distortion-animation></at-trends-lab-distortion-animation>
      <router-link
        to="/about"
        class="font-black inline-flex items-center font-2xl
        lg:mt-20
        md:mt-14
        mt-14"
      >
        {{ $t('home.more') }}
        <img
          class="inline ml-8"
          src="@/assets/img/circle-arrow.svg"
          alt="Follow link"
        />
      </router-link>
    </div>

    <!-- Trends -->
    <section
      class="
        xl:pb-28 xl:px-44
        lg:pb-28 lg:px-32
        md:pb-28 md:px-24
        pb-28 px-8"
      ref="trends"
    >
      <HomeTitle
        :title="$t('home.trendsReport')"
        :subtitle="$t('home.trends')"
      />
      <!-- Reports section -->
      <ReportsSection />
    </section>

    <section
      class="
        xl:pb-44 xl:px-44
        lg:pb-36 lg:px-32
        md:pb-28 md:px-24
        pb-28 px-8"
    >
      <HomeTitle
        :title="$t('home.publishing')"
        :subtitle="$t('home.editorial')"
      />
      <!-- Reports section -->
      <PublishingCarousel />
    </section>

    <!-- new phrase -->
    <CustomMarquee :text="$t('home.newPhrase')" />

    <!-- project list -->
    <section
      class="
      xl:pt-64 xl:px-44
      lg:pt-44 lg:px-32
      md:pt-36 md:px-24
      pt-36 px-8"
    >
      <div
        class="flex items-center mb-4
        md:text-base
        text-sm"
      >
        <div class="title-bar"></div>
        <span>{{ $t('home.projectList') }}</span>
      </div>
      <h1
        class="relative font-bold
        xl:mb-48
        lg:mb-32 lg:text-5xl
        mb-24 text-3xl"
      >
        {{ $t('home.brands') }}
      </h1>
      <div class="flex flex-wrap">
        <div
          class="flex justify-center items-center
          lg:py-20
          md:py-16 md:w-1/4
          py-8 w-1/2"
        >
          <img
            src="@/assets/img/uber.png"
            alt="Uber"
            class="object-contain"
            style="width: 106px"
          />
        </div>
        <div
          class="flex justify-center items-center
          lg:py-20
          md:py-16 md:w-1/4
          py-8 w-1/2"
        >
          <img
            src="@/assets/img/kimberly-clark.png"
            alt="Kimberly Clark"
            class="filter brightness-0 object-contain"
            style="width: 172px"
          />
        </div>
        <div
          class="flex justify-center items-center
          lg:py-20
          md:py-16 md:w-1/4
          py-8 w-1/2"
        >
          <img
            src="@/assets/img/luuna-logo.png"
            alt="UNAM"
            class="filter brightness-0 object-contain"
            style="width: 108px"
          />
        </div>
        <div
          class="flex justify-center items-center
          lg:py-20
          md:py-16 md:w-1/4
          py-8 w-1/2"
        >
          <img
            src="@/assets/img/linkedin.png"
            alt="Facultad de Artes y Diseño, UNAM"
            class="filter brightness-0 object-contain"
            style="width: 140px"
          />
        </div>
        <div
          class="flex justify-center items-center
          lg:py-20
          md:py-16 md:w-1/4
          py-8 w-1/2"
        >
          <img
            src="@/assets/img/levis.png"
            alt="Levi's"
            class="filter grayscale object-contain"
            style="width: 116px"
          />
        </div>
        <div
          class="flex justify-center items-center
          lg:py-20
          md:py-16 md:w-1/4
          py-8 w-1/2"
        >
          <img
            src="@/assets/img/nike.png"
            alt="Nike"
            class="object-contain"
            style="width: 126px"
          />
        </div>
        <div
          class="flex justify-center items-center
          lg:py-20
          md:py-16 md:w-1/4
          py-8 w-1/2"
        >
          <img
            src="@/assets/img/cdmx.png"
            alt="CDMX"
            class="object-contain"
            style="width: 146px"
          />
        </div>
        <div
          class="flex justify-center items-center
          lg:py-20
          md:py-16 md:w-1/4
          py-8 w-1/2"
        >
          <img
            src="@/assets/img/gnp.png"
            alt="GNP Seguros"
            class="filter brightness-0 object-contain"
            style="width: 156px"
          />
        </div>
        <div
          class="flex justify-center items-center
          lg:py-20
          md:py-16 md:w-1/4
          py-8 w-1/2"
        >
          <img
            src="@/assets/img/mcdonnalds.png"
            alt="McDonnald's"
            class="filter brightness-0 object-contain"
            style="width: 100px"
          />
        </div>
        <a
          href="https://chivasregal.trendslab.mx/"
          class="flex justify-center items-center
          lg:py-20
          md:py-16 md:w-1/4
          py-8 w-1/2"
        >
          <img
            src="@/assets/img/chivas-regal2.png"
            alt="Chivas Regal"
            class="object-contain"
            style="width: 138px"
          />
        </a>
        <div
          class="flex justify-center items-center
          lg:py-20
          md:py-16 md:w-1/4
          py-8 w-1/2"
        >
          <img
            src="@/assets/img/kraken.png"
            alt="Kraken"
            class="object-contain"
            style="width: 136px"
          />
        </div>
        <div
          class="flex justify-center items-center
          lg:py-20
          md:py-16 md:w-1/4
          py-8 w-1/2"
        >
          <img
            src="@/assets/img/jose-cuervo.png"
            alt="Jose Cuervo"
            class="h-12 object-contain"
            style="width: 148px"
          />
        </div>
      </div>
    </section>

    <!-- gallery carousel -->
    <section
      class="
        xl:pt-44 xl:pb-44
        lg:pt-36 lg:pb-36
        md:pt-28 md:pb-28
        pt-28 pb-28"
    >
      <gallery />
    </section>
  </div>
</template>

<script>
import '../assets/css/enter-animations.css'
import initEnterAnimations from '../assets/js/enter-animations'

// Components
import Hero from '../components/Hero.vue'
import Gallery from '../containers/Gallery.vue'
import HomeTitle from '../components/HomeTitle.vue'
import CustomMarquee from '../components/CustomMarquee.vue'
import ReportsSection from '../components/ReportsSection.vue'
import PublishingCarousel from '../components/PublishingCarousel.vue'
import AtTrendsLabDistortionAnimation from '../components/AtTrendsLabDistortionAnimation.vue'

// Assets
import iconMuted from '@/assets/img/volume-mute-filled.svg'
import iconUnmuted from '@/assets/img/volume-up-filled.svg'

// Redux
import { store } from '../main'

export default {
  name: 'Home',
  components: {
    AtTrendsLabDistortionAnimation,
    Gallery,
    ReportsSection,
    HomeTitle,
    PublishingCarousel,
    CustomMarquee,
    Hero,
  },
  data: () => ({
    sharedState: store.state,
    muted: true,
    iconMuted,
    iconUnmuted,
  }),
  computed: {
    carouselItems() {
      return this.galleries[this.currentGallery]
    },
  },

  methods: {
    /**
     * Navigates to /reports
     */
    gotoReports(year) {
      this.$router.push(`/reports-${year}`)
    },
  },
  mounted() {
    // trends hash
    if (window.location.hash.includes('trends'))
      window.scrollTo({
        top: this.$refs.trends.offsetTop - 100,
      })

    // enter animations
    this.disposeEnterAnimations = initEnterAnimations('.enter-home')
  },
  unmounted() {
    this.disposeEnterAnimations()
  },
}
</script>
