<template>
  <div
    class="flex items-center mb-4 enter-home enter-fade-up
      md:text-base
      text-sm"
  >
    <div class="title-bar"></div>
    <span>{{ subtitle }}</span>
  </div>
  <h1
    class="relative font-bold mb-20 enter-home enter-fade-up
      md:text-5xl
      text-3xl"
  >
    {{ title }}
  </h1>
</template>

<script>
export default {
  name: 'HomeTitle',
  props: ['title', 'subtitle'],
}
</script>
